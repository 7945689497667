import * as Yup from 'yup';

import formModel from './patientFormModel';
import { getDifferenceInYears } from '../../../../utils/DateUtil';
import { stringRegex } from '../../../../utils/FormUtil';
import { AttentionTypeEnum } from '../../../../models/enums';

const {
   first_name,
   last_name,
   sex_str,
   birth_date,
   id_number,
   phone_number,
   address_dto: {
      street,
      number,
      postal_code,
      between_streets,
      city,
      province,
      country,
   },
   patient_dto: { size, weight, attention_type, affiliate_number, prepaid_health_category },
} = formModel;

const stringErrorMsg = 'Ingresa sólo letras';
const typeNumberErrorMsg = 'Ingresa sólo números';
const typeDateErrorMsg = 'Ingresa una fecha válida';
const cpRegex = /^([a-zA-Z]\d{4}([a-zA-Z]){3})|^\d{4}$/;

// TODO: Update this validations when Backend do.
const patientValidationSchema = [
   Yup.object().shape({
      first_name: Yup.string()
         .matches(stringRegex, stringErrorMsg)
         .required(first_name.requiredErrorMsg),
      last_name: Yup.string()
         .matches(stringRegex, stringErrorMsg)
         .required(last_name.requiredErrorMsg),
      id_number: Yup.string()
         .required(id_number.requiredErrorMsg),
      sex_str: Yup.string().required(sex_str.requiredErrorMsg),
      birth_date: Yup.date()
         .typeError(typeDateErrorMsg)
         .required(birth_date.requiredErrorMsg)
         .test(
            birth_date.name,
            birth_date.minAgeErrorMsg,
            (value) => getDifferenceInYears(value) >= 18,
         ),
      phone_number: Yup.string()
         .min(10, phone_number.minErrorMsg)
         .max(10, phone_number.maxErrorMsg)
         .required(phone_number.requiredErrorMsg),
         
      affiliate_number: Yup.number().when('attentionTypes', {
         is: AttentionTypeEnum.HEALTHINSURANCE,
         then: Yup.number().typeError(typeNumberErrorMsg)
         .required(affiliate_number.requiredErrorMsg),
      })     
   }),
   Yup.object().shape({
      address_dto: Yup.object().shape({
         street: Yup.string().required(street.requiredErrorMsg),
         number: Yup.number()
            .typeError(typeNumberErrorMsg),
         postal_code: Yup.string()
            .min(4, postal_code.minErrorMsg)
            .max(8, postal_code.maxErrorMsg)
            .matches(cpRegex, postal_code.minErrorMsg)
            .required(postal_code.requiredErrorMsg),
         between_streets: Yup.string()
            .nullable()
            .min(3, between_streets.minErrorMsg)
            .max(90, between_streets.maxErrorMsg)
            .notRequired(),
         city: Yup.string()
            .min(3, city.minErrorMsg)
            .max(90, city.maxErrorMsg)
            .required(city.requiredErrorMsg),
          country: Yup.string().required(country.requiredErrorMsg),
         province: Yup.string()
            .min(3, province.minErrorMsg)
            .max(90, province.maxErrorMsg)
            .required(province.requiredErrorMsg),
      }),
   }),
   Yup.object().shape({
      patient_dto: Yup.object().shape({
         size: Yup.number().transform((_, value) => {
            return +String(value).replace(/,/, '.');
          }).typeError(typeNumberErrorMsg)
            .required(size.requiredErrorMsg),
         weight: Yup.number()
            .typeError(typeNumberErrorMsg)
            .required(weight.requiredErrorMsg),
      }),
   }),
];

export default patientValidationSchema;
