const capitalize = ([first,...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

export const showToast = (dispatch, message, toastType, title) => {
   dispatch({
      title: title ? capitalize(title) : '',
      toastType: toastType,
      message: message,
   });
};

export const ToastType = {
   ERROR: 'ERROR',
   INFO: 'INFO',
   SUCCESS: 'SUCCESS',
   WARNING: 'WARNING',
};

export function checkStatus(status) {
   switch (status) {
       case 'FINISHED':
           return "FINALIZADO";
       case 'STARTED':
           return "EMPEZANDO";
       case 'WAITING':
           return "ESPERANDO";
       case 'ERROR':
           return "ERROR";
       case 'QUIT':
            return "CANCELADO"
       case 'ACCEPTED':
            return "ACEPTADO"
       default:
           return "DESCONOCIDO";
   }
}

export function processEmail(email) {
    if (email.includes('#')) {
        let parts = email.split('#');
        return parts[0];
    }
    return email;
}