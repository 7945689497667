import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import SelectObjectField from "./SelectObjectField";
import {
  DatePickerField,
  InputField,
  RadioGroupField,
} from "../../../../components/FormFields";
import { useStateAuthValue } from "../../../../context/Auth/AuthState";
import { useToast } from "../../../../context/Toast/ToastProvider";
import { AttentionTypeEnum } from "../../../../models/enums";
import { GetMedilineFinancerByPatient } from "../../../../services/medilinePrepaidService";
import {
  GetPrepaidHealth,
  GetPrepaidHealthCategory,
} from "../../../../services/prepaidHealthService";
import genders from "../../../../tests/mocks/genders";
import { OK } from "../../../../utils/ServiceUtil";
import { ParentalRelationshipType } from "../../../../utils/ParentalRelationshipsUtil";
import { showToast, ToastType } from "../../../../utils/ToastUtil";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

const PersonalInformationPatientForm = ({
  formModel,
  isFam,
  isEditionOfMember,
  touched,
  particularPrepaidHealth, 
  setParticularPrepaidHealth,
  setParticularPrepaidHealthCategory,
  particularPrepaidHealthCategory
}) => {
  const {
    alias,
    first_name,
    last_name,
    id_number,
    birth_date,
    phone_number,
    sex_str,
    relationship_to_titular,
    patient_dto: { affiliate_number, prepaid_health_category },
  } = formModel;
  const [{ userData, userToken }] = useStateAuthValue();
  const [isDisable, setIsDisable] = useState(true);
  const [isRequired, setIsRequired] = useState(false);
  const [prepaidHealthList, setPrepaidHealthList] = useState([]);
  const [prepaidHealth, setPrepaidHealth] = useState("");
  const [parentalRelationship, setParentalRelationship] = useState("");
  const [medPrepaidHealthPlan, setMedPrepaidHealthPlan] = useState("");
  const [medPrepaidHealthAffNumb, setMedPrepaidHealthAffNumb] = useState("");
  const [prepaidHealthChanged, setPrepaidHealthChanged] = useState(false);
  const [isPrepaidHealthError, setIsPrepaidHealthError] = useState(false);
  const [prepaidHealthCategoryList, setPrepaidHealthCategoryList] = useState(
    []
  );
  const [prepaidHealthCategory, setPrepaidHealthCategory] = useState("");
  const [isPrepaidHealthCategoryError, setIsPrepaidHealthCategoryError] =
    useState(false);
  const { submitCount, values } = useFormikContext();
  const emptyPrepaidHealthCategoryList = prepaidHealthCategoryList.length === 0;
  const toastDispatch = useToast();
  const [hasPrepaidHealth, setHasPrepaidHealth] = useState(values?.patientMemberId !== undefined ? values?.patient_dto.prepaid_health_category?.name === ( 'PARTICULAR' || undefined ) ? false : true : userData?.patient_dto?.prepaid_health_category?.name === ( 'PARTICULAR' || undefined ) ? false : true);
  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };

  useEffect(() => {
    values?.patient_dto?.attention_type === AttentionTypeEnum.HEALTHINSURANCE
  },[values?.patient_dto?.attention_type])

  useEffect(() => {
    const getPlan = async () => {
      await GetMedilineFinancerByPatient(userToken, userData.patient_dto.id)
        .then((res) => {
          setMedPrepaidHealthPlan(res.planName);
          setMedPrepaidHealthAffNumb(res.affiliateNumber);
        })
        .catch((e) => {
          if (process.env.REACT_APP_DEBUG === "true") {
            const error =
              "Error:  ~ file: PersonalInformationPatientForm.js:71 ~ getPlan ~ Error:" +
              e;
            showToast(toastDispatch, error, ToastType.ERROR, ToastType.ERROR);
          }
        });
    };
    if (userData?.patient_dto.id) {
      getPlan();
    }
  }, []);

  useEffect(() => {
    if (!prepaidHealth) {
      if (values?.patientMemberId !== undefined) {
        setPrepaidHealth(
          values?.patient_dto?.prepaid_health_category?.prepaid_health || ""
        );
      } else {
        setPrepaidHealth(
          userData?.patient_dto?.prepaid_health_category?.prepaid_health || ""
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepaidHealth]);

  useEffect(() => {
    if (prepaidHealthChanged) {
      setPrepaidHealthCategory("");
    } else if (!prepaidHealthChanged) {
      if (values?.patientMemberId !== undefined) {
        setPrepaidHealthCategory(
          values?.patient_dto?.prepaid_health_category || ""
        );
      } else {
        setPrepaidHealthCategory(
          userData?.patient_dto?.prepaid_health_category || ""
        );
      }
    }
  }, [prepaidHealthChanged]);
  useEffect(() => {
    if (isFam) {
      setIsDisable(false);
      setIsRequired(true);
    }
  }, [isFam]);

  useEffect(() => {
    if (prepaidHealthCategory || prepaidHealth) {
      const prepaidValues = {
        id: prepaidHealthCategory?.id,
        name: prepaidHealthCategory?.name,
        prepaid_health: {
          id: prepaidHealth?.id,
          name: prepaidHealth?.name,
        },
      };
      values.patient_dto.prepaid_health_category = prepaidValues;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepaidHealthCategory, prepaidHealth]);

  useEffect(() => {
    if (relationship_to_titular) {
      const name = ParentalRelationshipType.map((p) => {
        if (p.value === values?.relationship_to_titular) return p.name;
      });
      setParentalRelationship({ value: values?.relationship_to_titular, name });
    }
    if (isEditionOfMember)
      values.relationship_to_titular = parentalRelationship;
  }, [relationship_to_titular]);

  useEffect(() => {
    if (parentalRelationship) {
      values.relationship_to_titular = parentalRelationship.value;
    }
  }, [parentalRelationship]);

  useEffect(() => {
    fetchPrepaidHealthList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.patient_dto?.attention_type]);

  useEffect(() => {
    if (prepaidHealth && prepaidHealth !== "" && prepaidHealth?.id) {
      fetchPrepaidHealthCategoryList(prepaidHealth?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepaidHealth]);

  useEffect(() => {
    fetchParticularPrepaidHealthCategory();
  },[])

  useEffect(() => {
    if (submitCount > 0) {
      if (!prepaidHealth) {
        setIsPrepaidHealthError(true);
      } else {
        setIsPrepaidHealthError(false);
      }
      if (!prepaidHealthCategory) {
        setIsPrepaidHealthCategoryError(true);
      } else {
        setIsPrepaidHealthCategoryError(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepaidHealth, prepaidHealthCategory, submitCount]);

  const fetchPrepaidHealthList = async () => {
    const errorMessage = "Ocurrió un error en la búsqueda de obras sociales";

    await GetPrepaidHealth(userToken)
      .then((response) => {
        if (OK === response?.status) {
          const updatedData = response.data.filter((obj) => obj.name !== 'PARTICULAR');
          const removedObj = response.data.find((obj) => obj.name === 'PARTICULAR');
          setPrepaidHealthList(updatedData);
          setParticularPrepaidHealth(removedObj);
        } else {
          showError(errorMessage);
        }
      })
      .catch((error) => {
        showError(errorMessage)
      });
  };

  const fetchParticularPrepaidHealthCategory = async () => {
    const errorMessagePrepaidHealthCategoryList = "Ocurrió un error en la búsqueda de planes";
    const errorMessagePrepaidHealth = "Ocurrió un error en la búsqueda de obras sociales"; 
    await GetPrepaidHealth(userToken)
      .then(async (response) => {
        if (OK === response?.status) {
          const particularPrepaidHealthValue = response.data.find((obj) => obj.name === 'PARTICULAR');
          await GetPrepaidHealthCategory(userToken, particularPrepaidHealthValue?.id)
            .then((response) => {
              if (OK === response?.status) {
                const particularPrepaidHealthCategoryValue = response.data.find((obj) => obj.name === 'PARTICULAR');
                setParticularPrepaidHealthCategory(particularPrepaidHealthCategoryValue);
              } else {
                showError(errorMessagePrepaidHealthCategoryList);
              }
            })
            .catch((error) => {
              showError(errorMessagePrepaidHealthCategoryList)
            });
        } else {
          showError(errorMessagePrepaidHealth);
        }
      })
      .catch((error) => {
        showError(errorMessagePrepaidHealth)
      });
  };


  const fetchPrepaidHealthCategoryList = async (prepaidHealthSelected) => {
    const errorMessage = "Ocurrió un error en la búsqueda de planes";
    await GetPrepaidHealthCategory(userToken, prepaidHealthSelected)
      .then((response) => {
        if (OK === response?.status) {
          const updatedData = response.data.filter((obj) => obj.name !== 'PARTICULAR');
          const removedObj = response.data.find((obj) => obj.name === 'PARTICULAR');
          setPrepaidHealthCategoryList(updatedData);
          setParticularPrepaidHealthCategory(removedObj);
        } else {
          showError(errorMessage);
        }
      })
      .catch(() => showError(errorMessage));
  };

  const renderExtraLabel = () => {
    if (prepaidHealth) {
      if (emptyPrepaidHealthCategoryList) {
        return "No hay planes para esta obra social";
      } else {
        return "";
      }
    } else {
      return "Seleccioná primero tu obra social";
    }
  };

  // is fam member editing, everything disabled
  if (isFam && isEditionOfMember) {
    return (
      <>
        <div className="header">
          <h2>Información Personal</h2>
        </div>
        <div className="form">
          <div>
            <InputField
              name={alias.name}
              label={alias.label}
              className="field"
              style={{ width: "100%" }}
              disabled={false}
            />
            <div className="detail-wrapper">
              <InputField
                name={first_name.name}
                label={first_name.label}
                className="field"
                disabled={false}
              />
              <InputField
                name={last_name.name}
                label={last_name.label}
                className="field"
                disabled={false}
              />
              <InputField
                name={id_number.name}
                label={id_number.label}
                className="field"
                disabled={true}
                requiredField
              />
            </div>
            <div className="detail-wrapper-two-colum">
              <DatePickerField
                name={birth_date.name}
                label={birth_date.label}
                format="dd/MM/yyyy"
                minDate={new Date(birth_date.minDate)}
                maxDate={new Date()}
                disableFuture
                views={["year", "month", "date"]}
                openTo="year"
                className="field"
                disabled={false}
              />
              <InputField
                name={phone_number.name}
                label={phone_number.label}
                extraLabel={phone_number.extraLabel}
                className="field"
                disabled={true}
              />
              <SelectObjectField
                label="Parentesco"
                value={parentalRelationship}
                data={ParentalRelationshipType}
                setValue={setParentalRelationship}
                className="field"
                disabled={false}
              />
            </div>
            <div>
              <RadioGroupField
                name={sex_str.name}
                label={sex_str.label}
                data={genders}
                extraLabel={sex_str.extraLabel}
                className="field field--radio"
                disabled={true}
              />
            </div>
          </div>
          <div>
            <div className="detail-wrapper-one-column">
              <FormLabel component="legend" className={`field-label`}>
                Tipo de atención
              </FormLabel>
              <p style={{ fontWeight: 600 }}>Plan Mediline</p>
            </div>
            <div className="detail-wrapper">
              <InputField
                name="prestador"
                label="Prestador"
                value={"Mediline"}
                className="field"
                disabled={true}
              />
              <InputField
                name="plan"
                label="Plan"
                value={medPrepaidHealthPlan}
                className="field"
                disabled={true}
              />
              <InputField
                name="num-afiliado-med"
                label="N° de asociado"
                value={medPrepaidHealthAffNumb}
                className="field"
                disabled={true}
              />
            </div>
            <RadioGroup className="radio-group">
              <div className="detail-wrapper-two-colum">
                <FormControlLabel
                  value={"prepaidHealht"}
                  control={<Radio color="secondary" />}
                  label={
                    <Typography style={{ fontWeight: 800, fontSize: "14px" }}>
                      Tengo Obra Social / Prepaga
                    </Typography>
                  }
                  checked={hasPrepaidHealth}
                  onClick={() => {
                    setHasPrepaidHealth(true);
                    setPrepaidHealth({});
                    setPrepaidHealthCategory({});
                    const prepaidValues = {
                      id: '',
                      name: '',
                      prepaid_health: {
                        id: '',
                        name: '',
                      },
                    };
                    values.patient_dto.prepaid_health_category = prepaidValues;
                    values.patient_dto.attention_type =
                      AttentionTypeEnum.HEALTHINSURANCE;
                  }}
                />
                <FormControlLabel
                  value={"particular"}
                  control={<Radio color="secondary" />}
                  label={
                    <Typography style={{ fontWeight: 800, fontSize: "14px" }}>
                      Soy Particular
                    </Typography>
                  }
                  checked={!hasPrepaidHealth}
                  onClick={() => {
                    setHasPrepaidHealth(false);
                    setPrepaidHealth(particularPrepaidHealth);
                    values.patient_dto.attention_type =
                    AttentionTypeEnum.PARTICULAR;
                    setPrepaidHealthCategory(particularPrepaidHealthCategory);
                    const prepaidValues = {
                      id: particularPrepaidHealthCategory?.id,
                      name: particularPrepaidHealthCategory?.name,
                      prepaid_health: {
                        id: particularPrepaidHealth?.id,
                        name: particularPrepaidHealth?.name,
                      },
                    };
                    setTimeout(() => {
                      console.log(particularPrepaidHealthCategory);  
                    }, 500);
                    values.patient_dto.prepaid_health_category = prepaidValues;
                  }}
                />
              </div>
            </RadioGroup>
            {hasPrepaidHealth && (
              <>
                <p style={{ fontWeight: 600 }}>Obra Social</p>
                <div className="detail-wrapper">
                  <div>
                  <SelectObjectField
                    label="Obra Social o Prepaga"
                    name='prepaid_health'
                    value={prepaidHealth}
                    setValue={setPrepaidHealth}
                    data={prepaidHealthList}
                    className="field"
                    errorMessage={isPrepaidHealthError}
                    setChanged={setPrepaidHealthChanged}
                    containerStyle={{
                      minHeight: 'min-content'
                    }}
                  />
                  {values?.patient_dto.attention_type === AttentionTypeEnum?.HEALTHINSURANCE && touched?.prepaid_health && <p className="MuiFormHelperText-root Mui-error">La obra social es requerida</p>}
                  </div>
                  <div>
                  <SelectObjectField
                    label="Plan"
                    name='prepaid_health_category'
                    defaultValue=""
                    value={prepaidHealthCategory}
                    setValue={setPrepaidHealthCategory}
                    data={prepaidHealthCategoryList}
                    className="field"
                    errorMessage={isPrepaidHealthCategoryError}
                    disabled={!prepaidHealth || emptyPrepaidHealthCategoryList}
                    extraLabel={renderExtraLabel()}
                    containerStyle={{
                      minHeight: 'min-content'
                    }}
                  />
                  {values?.patient_dto?.attention_type === AttentionTypeEnum?.HEALTHINSURANCE && touched?.prepaid_health_category && <p className="MuiFormHelperText-root Mui-error">El plan es requerido</p>}
                  </div>
                  <div>
                  <InputField
                    name={affiliate_number?.name}
                    label={affiliate_number?.label}
                    className="field"
                    requiredField
                    containerStyle={{
                      minHeight: 'min-content'
                    }}
                  />
                  {values?.patient_dto?.attention_type === AttentionTypeEnum?.HEALTHINSURANCE && touched?.affiliate_number && <p className="MuiFormHelperText-root Mui-error">El número de afiliado es requerido</p>}
                  </div>
                  
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
    // is profile editing or new fam member
  } else {
    return (
      <>
        <div className="header">
          <h2>Información Personal</h2>
        </div>
        <div className="form">
          <div>
            <InputField
              name={alias.name}
              label={alias.label}
              className="field"
              style={{ width: "100%" }}
            />
            <div className="detail-wrapper">
              <InputField
                name={first_name.name}
                label={first_name.label}
                className="field"
                requiredField
              />
              <InputField
                name={last_name.name}
                label={last_name.label}
                className="field"
                requiredField
              />
              {isRequired ? (
                <InputField
                  name={id_number.name}
                  label={id_number.label}
                  className="field"
                  disabled={isDisable}
                />
              ) : (
                <InputField
                  name={id_number.name}
                  label={id_number.label}
                  className="field"
                  disabled={isDisable}
                  requiredField
                />
              )}
            </div>
            <div className="detail-wrapper-two-colum">
              <DatePickerField
                name={birth_date.name}
                label={birth_date.label}
                format="dd/MM/yyyy"
                minDate={new Date(birth_date.minDate)}
                maxDate={new Date()}
                disableFuture
                views={["year", "month", "date"]}
                openTo="year"
                className="field"
                requiredField
              />
              {isFam ? (
                <InputField
                  name={phone_number.name}
                  label={phone_number.label}
                  extraLabel={phone_number.extraLabel}
                  className="field"
                  disabled={true}
                />
              ) : (
                <InputField
                  name={phone_number.name}
                  label={phone_number.label}
                  extraLabel={phone_number.extraLabel}
                  className="field"
                  requiredField
                />
              )}
              {isFam && (
                <SelectObjectField
                  label="Parentesco"
                  value={parentalRelationship}
                  data={ParentalRelationshipType}
                  setValue={setParentalRelationship}
                  className="field"
                />
              )}
            </div>
            <div>
              <RadioGroupField
                name={sex_str.name}
                label={sex_str.label}
                data={genders}
                extraLabel={sex_str.extraLabel}
                className="field field--radio"
                requiredField
              />
            </div>
          </div>
          <div>
            <div className="detail-wrapper-one-column">
              <FormLabel component="legend" className={`field-label`}>
                Tipo de atención
              </FormLabel>
              <p style={{ fontWeight: 600 }}>Plan Mediline</p>
              {/* <p style={{ fontWeight: 600 }}>Particular</p>
                  <p style={{ fontWeight: 600 }}>Plan</p> */}
            </div>
            <div className="detail-wrapper">
              <InputField
                name="prestador"
                label="Prestador"
                value={"Mediline"}
                className="field"
                disabled={true}
              />
              <InputField
                name="plan"
                label="Plan"
                value={medPrepaidHealthPlan}
                className="field"
                disabled={true}
              />
              <InputField
                name="num-afiliado-med"
                label="N° de asociado"
                value={medPrepaidHealthAffNumb}
                className="field"
                disabled={true}
              />
            </div>
            <RadioGroup className="radio-group">
              <div className="detail-wrapper-two-colum">
                <FormControlLabel
                  value={"prepaidHealht"}
                  control={<Radio color="secondary" />}
                  label={
                    <Typography style={{ fontWeight: 800, fontSize: "14px" }}>
                      Tengo Obra Social / Prepaga
                    </Typography>
                  }
                  checked={hasPrepaidHealth}
                  onClick={() => {
                    values.patient_dto.attention_type =
                      AttentionTypeEnum.HEALTHINSURANCE
                    setHasPrepaidHealth(true);
                    setPrepaidHealth({});
                    setPrepaidHealthCategory({});
                    const prepaidValues = {
                      id: '',
                      name: '',
                      prepaid_health: {
                        id: '',
                        name: '',
                      },
                    };
                    values.patient_dto.prepaid_health_category = prepaidValues;
                    ;
                  }}
                />
                <FormControlLabel
                  value={"particular"}
                  control={<Radio color="secondary" />}
                  label={
                    <Typography style={{ fontWeight: 800, fontSize: "14px" }}>
                      Soy Particular
                    </Typography>
                  }
                  checked={!hasPrepaidHealth}
                  onClick={() => {
                    values.patient_dto.attention_type =
                      AttentionTypeEnum.PARTICULAR;
                    setHasPrepaidHealth(false);
                    setPrepaidHealth(particularPrepaidHealth);
                    setPrepaidHealthCategory(particularPrepaidHealthCategory);
                    const prepaidValues = {
                      id: particularPrepaidHealthCategory?.id,
                      name: particularPrepaidHealthCategory?.name,
                      prepaid_health: {
                        id: particularPrepaidHealth?.id,
                        name: particularPrepaidHealth?.name,
                      },
                    };
                    setTimeout(() => {
                      console.log(particularPrepaidHealthCategory);  
                    }, 500);
                    values.patient_dto.prepaid_health_category = prepaidValues;
                  }}
                />
              </div>
            </RadioGroup>
            {hasPrepaidHealth && (
              <>
                <p style={{ fontWeight: 600 }}>Obra Social o Prepaga</p>
                <div className="detail-wrapper">
                  <div>
                  <SelectObjectField
                    label="Obra Social o Prepaga"
                    name='prepaid_health'
                    value={prepaidHealth}
                    setValue={setPrepaidHealth}
                    data={prepaidHealthList}
                    className="field"
                    errorMessage={isPrepaidHealthError}
                    setChanged={setPrepaidHealthChanged}
                    containerStyle={{
                      minHeight: 'min-content'
                    }}
                  />
                  {values.patient_dto.attention_type === AttentionTypeEnum.HEALTHINSURANCE && touched.prepaid_health && <p className="MuiFormHelperText-root Mui-error">La obra social es requerida</p>}
                  </div>
                  <div>
                  <SelectObjectField
                    label="Plan"
                    name='prepaid_health_category'
                    defaultValue=""
                    value={prepaidHealthCategory}
                    setValue={setPrepaidHealthCategory}
                    data={prepaidHealthCategoryList}
                    className="field"
                    errorMessage={isPrepaidHealthCategoryError}
                    disabled={!prepaidHealth || emptyPrepaidHealthCategoryList}
                    extraLabel={renderExtraLabel()}
                    containerStyle={{
                      minHeight: 'min-content'
                    }}
                  />
                  {values.patient_dto.attention_type === AttentionTypeEnum.HEALTHINSURANCE && touched.prepaid_health_category && <p className="MuiFormHelperText-root Mui-error">El plan es requerido</p>}
                  </div>
                  <div>
                  <InputField
                    name={affiliate_number.name}
                    label={affiliate_number.label}
                    className="field"
                    requiredField
                    containerStyle={{
                      minHeight: 'min-content'
                    }}
                  />
                  {values.patient_dto.attention_type === AttentionTypeEnum.HEALTHINSURANCE && touched.affiliate_number && <p className="MuiFormHelperText-root Mui-error">El número de afiliado es requerido</p>}
                  </div>
                  
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default PersonalInformationPatientForm;
