import Wrapper from "./wrapper";
import axios from "axios";
import {config} from "../config";
import decode from "jwt-decode";
import scheduleSchemaOldResponse from "../contracts/schedule/schedule.old.schema.json";
import scheduleAvailabilitySchemaOldResponse from "../contracts/schedule/availability.old.schema.rs.json";
import ParametricsWrapper from "./parametrics.wrapper";
import ProfessionalWrapper from "./professional.wrapper";

const scheduleMapperRs = async (sch, jwtToken) => {
	scheduleSchemaOldResponse.check_in = sch.checkIn;
	scheduleSchemaOldResponse.check_out = sch.checkOut;
	scheduleSchemaOldResponse.days = sch.dayOfTheWeek;
	scheduleSchemaOldResponse.appointment_duration = sch.timeSlotDuration;
	scheduleSchemaOldResponse.last_time_avalability = sch.endDate;
	scheduleSchemaOldResponse.schedulePrepaidHealths = sch.schedulePrepaidHealths;

	scheduleSchemaOldResponse.time_slot_duration = sch.time_slot_duration;
	scheduleSchemaOldResponse.time_slot_duration_unit = sch.time_slot_duration_unit;
	scheduleSchemaOldResponse.duration = sch.duration;
	scheduleSchemaOldResponse.start_date = sch.start_date;
	scheduleSchemaOldResponse.duration_unit = sch.duration_unit;

	if (sch.schedulePrepaidHealths && sch.schedulePrepaidHealths.length > 0) {
		const prw = new ParametricsWrapper();
		const phs = await prw.searchPrepaidHealths(jwtToken, sch.schedulePrepaidHealths);
		scheduleSchemaOldResponse.name = phs[0].name;
	} else {
		scheduleSchemaOldResponse.name = "Particular"
	}
	scheduleSchemaOldResponse.id = sch.id;
	return scheduleSchemaOldResponse;
};

const scheduleMapperRq = async (sch, jwtToken) => {
	const decodedToken = decode(jwtToken)
	return {
		attentionTypeParticular: sch.attentionType === 1,
		checkIn: sch.check_in,
		checkOut: sch.check_out,
		dayOfTheWeek: sch.days,
		enabled: true,
		professionalLogin: decodedToken.sub,
		schedulePrepaidHealths: sch.id_prepaid_healths.length > 0 && !sch.id_prepaid_healths.includes('') ? sch.id_prepaid_healths?.map(e => {
			return {prepaidHealthId: e}
		}) : [],

		timeSlotDuration: sch.time_slot_duration,
		timeSlotDurationUnit: sch.time_slot_duration_unit,
		startDate: sch.start_date,
		duration: sch.duration,
		durationUnit: sch.duration_unit
	};
};
const scheduleAvailabilityRs = async (professional, slots,scheduleId) => {
	scheduleAvailabilitySchemaOldResponse.data.name = `${professional.data.first_name} ${professional.data.last_name}`;
	scheduleAvailabilitySchemaOldResponse.data.specialties = professional.data.professional_dto.specialties;
	scheduleAvailabilitySchemaOldResponse.data.licence = professional.data.professional_dto.licence_str;
	scheduleAvailabilitySchemaOldResponse.data.academic_training = professional.data.professional_dto.academic_training;
	scheduleAvailabilitySchemaOldResponse.data.time_availabilities_dto = slots.map(e=>{return{
		date: e.date,
		time_availability_dto: e.timeAvailabilitySlots.map(f=>{
			return {
				id: f.id,
				hour_from: f.hourFrom,
				hour_to: f.hourTo,
				id_schedule: scheduleId,
				available: f.enabled
			};
		})
	};});
	return scheduleAvailabilitySchemaOldResponse;
};

class ScheduleWrapper extends Wrapper {
	constructor() {
		super();
	}

	async scheduleCreate(jwtToken, body) {
		try {
			const response = await axios.post(
				config.api.schedule.SCHEDULE_SERVICE_PATH,
				await scheduleMapperRq(body[0], jwtToken),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw scheduleCreate: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getScheduleByProfessional(jwtToken, id) {
		try {
			const decodedToken = decode(jwtToken);
			const response = await axios.get(
				this.concatNotNullParam(config.api.schedule.SCHEDULE_SERVICE_PATH, {professionalLogin: decodedToken.sub}),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			let schedules = [];
			for (const r of response.data.content) {
				schedules.push({...await scheduleMapperRs(r, jwtToken)});
			}
			return {status: 'OK', data: [{schedules: schedules}]};
		} catch (error) {
			error.message = `Error mdw getScheduleByProfessional: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async scheduleValidate(jwtToken, body) {
		try {
			const response = await axios.delete(
				`${config.api.schedule.SCHEDULE_SERVICE_PATH}/${body.schedule_ids}`,
				{
					headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}
				}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw scheduleValidate: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async scheduleDelete(jwtToken, body) {
		try {
			const response = await axios.delete(
				config.api.schedule.SCHEDULE_SERVICE_PATH,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}, data: body}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw scheduleDelete: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async scheduleAvailability(jwtToken, id, params) {
		try {
			const response = await axios.get(
				this.concatNotNullParam(`${config.api.time_slot.TIME_SLOT_AVAILABILITY_ENDPOINT}/availability`, params),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			const profProfile = await new ProfessionalWrapper().getProfessional(jwtToken, id);
			return await scheduleAvailabilityRs(profProfile, response.data,params.scheduleId);
		} catch (error) {
			error.message = `Error mdw getDocTimeAvailability: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	}
}

export default ScheduleWrapper;
