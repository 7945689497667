import React,{useState, useEffect} from 'react';
import { useFormikContext } from 'formik';
import SelectObjectField from './SelectObjectField';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { GetProvince, GetLocation } from '../../../../services/provincesService';
import { showToast, ToastType } from '../../../../utils/ToastUtil';
import { useToast } from '../../../../context/Toast/ToastProvider';

import { InputField, SelectField } from '../../../../components/FormFields';
// TODO: Change this mock for a Service
import countries from '../../../../tests/mocks/countries';

const AddressForm = ({ formModel, isFam }) => {
   const {
      address_dto: {
         street,
         number,
         floor,
         number_department,
         postal_code,
         between_streets,
         city,
         country,
         province,
      },
   } = formModel;
   const { values } = useFormikContext();
   const [{ userToken }] = useStateAuthValue();
   const [cityDisabled,setCityDisabled] = useState(true);
   const [provinceList, setProvinceList] = useState([]);
   const [cityChosed, setCityChosed] = useState({name:values.address_dto.city});
   const [cityList, setCityList] = useState([]);
   const [provinceChosed, setProvinceChosed] = useState({name:values.address_dto.province});

   const toastDispatch = useToast();

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
   }; 

   const getLocationList = async () => {
      await GetProvince(userToken)
      .then((response) => {
         setProvinceList(response);
         if(provinceChosed){
            response.map((p) => {
               if(p.name === provinceChosed.name){
                  setProvinceChosed(p)
               }
            })
         }
      })
      .catch((e) => showError(e));
   };

   useEffect(() => {
      getLocationList();
   },[]);

   useEffect(() => {
      const getLocation = async () => {
         await GetLocation(userToken, provinceChosed?.id)
         .then((response) => {
            setCityDisabled(false);
            setCityList(response);
         })
         .catch((e) => showError(e));
      }
      if(provinceChosed?.id){
         getLocation();
      }
   },[provinceChosed])

   useEffect(() => {
      if(values.address_dto.city){
         setCityDisabled(false);
      } 
   },[values])

   useEffect(() => {
      if(!provinceChosed){
         setProvinceChosed( values.address_dto.province)
      } else{
         values.address_dto.province = provinceChosed.name;
      }
   },[provinceChosed]);

   useEffect(() => {
      if(!cityChosed){
         setCityChosed({name:values.address_dto.city})
      } else {
         values.address_dto.city = cityChosed.name;
      }
   },[cityChosed]);
   return (
      <>
         <div className="header">
            <h2>Dirección</h2>
         </div>
         {isFam ?
         <div className="form">
            <div>
               <InputField
                  name={street.name}
                  label={street.label}
                  className="field"
                  disabled={true}
                  style={{width : "100%"}}
               />
               <div className="field-wrapper">
                  <InputField
                     name={number.name}
                     label={number.label}
                     className="field"
                     disabled={true}
                     style={{width : "50%"}}
                  />
                  <InputField
                     name={floor.name}
                     label={floor.label}
                     className="field"
                     disabled={true}
                     style={{width : "50%"}}
                  />
               </div>
               <div className="field-wrapper">
                  <InputField
                     name={number_department.name}
                     label={number_department.label}
                     className="field"
                     disabled={true}
                     style={{width : "50%"}}
                  />
                  <InputField
                     name={postal_code.name}
                     label={postal_code.label}
                     className="field"
                     maxLength = {8}
                     disabled={true}
                     style={{width : "50%"}}
                  />
               </div>
               <InputField
                  name={between_streets.name}
                  label={between_streets.label}
                  className="field"
                  disabled={true}
                  style={{width : "100%"}}
               />
            </div>
            <div>
{/*                <SelectField
                  name={country.name}
                  label={country.label}
                  data={countries}
                  className="field"
                  disabled={false}
                  defaultValue={countries[0].name}
                  style={{width : "100%"}}
               /> */}
               <InputField
                  name={country.name}
                  label={country.label}
                  className="field"
                  disabled={true}
                  value={countries[0].name}
                  style={{width : "100%"}}
               />
               <SelectObjectField
                  name={province.name}
                  label={province.label}
                  defaultValue = ""
                  value={provinceChosed}
                  setValue={setProvinceChosed}
                  data={provinceList}
                  className="field"
                  disabled={true}
                  style={{width : "100%"}}
               />
               <SelectObjectField
                  name={city.name}
                  label={city.label}
                  defaultValue = ""
                  value={cityChosed}
                  setValue={setCityChosed}
                  data={cityList}
                  className="field"
                  disabled={true}
                  />
            </div>
         </div>
         :
         <div className="form">
            <div>
               <InputField
                  name={street.name}
                  label={street.label}
                  className="field"
                  requiredField
                  style={{width : "100%"}}
               />
               <div className="field-wrapper">
                  <InputField
                     name={number.name}
                     label={number.label}
                     className="field"
                     style={{width : "50%"}}
                  />
                  <InputField
                     name={floor.name}
                     label={floor.label}
                     className="field"
                     style={{width : "50%"}}
                  />
               </div>
               <div className="field-wrapper">
                  <InputField
                     name={number_department.name}
                     label={number_department.label}
                     className="field"
                     style={{width : "50%"}}
                  />
                  <InputField
                     name={postal_code.name}
                     label={postal_code.label}
                     className="field"
                     requiredField
                     maxLength = {8}
                     style={{width : "50%"}}
                  />
               </div>
               <InputField
                  name={between_streets.name}
                  label={between_streets.label}
                  className="field"
                  style={{width : "100%"}}
               />
            </div>
            <div>
               {/* <InputField
                  name={country.name}
                  label={country.label}
                  className="field"
                  disabled={false}
                  value={countries[0].name}
                  style={{width : "100%"}}
                  requiredField
               /> */}
               <SelectField
                  name={country.name}
                  label={country.label}
                  // data={countries}
                  data={[{ name: 'Argentina' }]}
                  className="field"
                  disabled={false}
                  defaultValue={countries[0].name}
                  requiredField
                  style={{width : "100%"}}
               />
               <SelectObjectField
                  name={province.name}
                  label={province.label}
                  defaultValue = ""
                  value={provinceChosed}
                  setValue={setProvinceChosed}
                  data={provinceList}
                  className="field"
                  style={{width : "100%"}}
               />
               <SelectObjectField
                  name={city.name}
                  label={city.label}
                  defaultValue = ""
                  value={cityChosed}
                  setValue={setCityChosed}
                  data={cityList}
                  className="field"
                  disabled={cityDisabled}
                  requiredField
                  />
            </div>
         </div>
         }
         
      </>
   );
};

export default AddressForm;
