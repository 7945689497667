import {config} from "../config";
import axios from "axios";
import Wrapper from "./wrapper";
import ParametricsWrapper from "./parametrics.wrapper";

const appointmentMapperRq = (data) => {
	return {
		attentionTypeParticular: data.attention_type_id === 1,
		enabled: true,
		patientLogin: data.patient_dto.userLogin,
		prepaidHealthId: data.patient_dto?.prepaid_health_id || null,
		specialtyId: data.specialty.id,
		status: data.status,
		timeSlot: {
			id: data.time_availability_id,
			schedule: {
				id: data.schedule_dto.id
			}
		}
	}
};

class AppointmentWrapper extends Wrapper {
	constructor() {
		super();
	}

	async createAppointment(jwtToken, body) {
		try {
			const response = await axios.post(
				config.api.appointment.APPOINTMENT_ENDPOINT,
				appointmentMapperRq(body),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status: 'OK', data: response.data};
		} catch (error) {
			error.message = `Error mdw createAppointment: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getAppointments(jwtToken, params) {
		try {
			const response = await axios.get(
				this.concatNotNullParam(config.api.appointment.APPOINTMENT_MY_APPOINTMENTS_ENDPOINT, params),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			let appointments = [];
			const parw = new ParametricsWrapper();

			for (const a of response.data) {
				let obj = {};
				obj.id = a.appointment.id;
				obj.professional_dto = {first_name:a.professional.firstName, last_name: a.professional.lastName, id:a.professional.userLogin};
				obj.patient_dto = {first_name:a.patient.firstName, last_name: a.patient.lastName, id:a.patient.userLogin};
				if(a.appointment.prepaidHealthId !== null)
					obj.patient_dto.prepaid_health = await parw.getPrepaidHealth(jwtToken, a.appointment.prepaidHealthId);
				obj.appointment_date = a.appointment.timeSlot.hourFrom;
				obj.status = a.appointment.status;
				obj.specialty = await parw.getSpecialty(jwtToken, a.appointment.specialtyId);
				appointments.push(obj);
			}
			return {status: 'OK', data: appointments};
		} catch (error) {
			error.message = `Error mdw getAppointments: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async putAppointment(jwtToken, id, body) {
		try {
			const response = await axios.patch(
				`${config.api.appointment.APPOINTMENT_ENDPOINT}/${id}`,
				body,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status: 'ok',data:response.data};
		} catch (error) {
			error.message = `Error mdw putAppointment: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};
}

export default AppointmentWrapper;
