import Wrapper from "./wrapper";
import axios from "axios";
import {config} from "../config";
import professionalOldResponse from "../contracts/professional/professional.old.schema.rs.json";
import {userDataMapperRs, userDataMapperRq} from "../commons/user.data.mapper";
import decode from "jwt-decode";
import {addressMapperRq, addressMapperRs} from "../commons/address.mapper";
import professionalSchemaRequest from "../contracts/professional/professional.schema.rq.json";
import ParametricsWrapper from "./parametrics.wrapper";
import ConsultationWrapper from "./consultation.wrapper";
import UserProfileWrapper from "./user.profile.wrapper";

const professionalMapperRs = (professional) => {
	let obj = {data:{professional_dto:{}}};
	obj.data.professional_dto.id = professional.id;
	obj.data.professional_dto.type_licence_str = professional.typeLicense;
	obj.data.professional_dto.academic_training = professional.academicTraining;
	obj.data.professional_dto.full_profile = professional.fullProfile;
	obj.data.professional_dto.attention_particular = professional.attentionParticular;
	obj.data.professional_dto.staff_mediline = professional.staffMediline;
	obj.data.professional_dto.virtual_guard_availability_str = professional.virtualGuardAvailability;
	obj.data.professional_dto.userLogin = professional.userLogin;
	obj.data.professional_dto.licence_str = professional.license;
	obj.data = {
		...obj.data,
		...userDataMapperRs(professional.userData)
	};


	return obj;
};

const professionalMapperRq = (professional) => {
	if (professional.professional_dto.id)
		professionalSchemaRequest.id = professional.professional_dto.id;
	professionalSchemaRequest.academicTraining = professional.professional_dto.academic_training;
	professionalSchemaRequest.attentionParticular = professional.professional_dto?.attention_particular || false;
	professionalSchemaRequest.license = professional.professional_dto.licence_str;
	professionalSchemaRequest.staffMediline = professional.professional_dto.staff_mediline;
	professionalSchemaRequest.typeLicense = professional.professional_dto.type_licence_str;
	professionalSchemaRequest.virtualGuardAvailability = professional.professional_dto.virtual_guard_availability_str;

	professionalSchemaRequest.userData = userDataMapperRq(professional);
	if (professional.id)
		professionalSchemaRequest.userData.id = professional.id;
	professionalSchemaRequest.userData.addresses = [];
	professionalSchemaRequest.userData.addresses.push(addressMapperRq(professional.address_dto));
	professionalSchemaRequest.userLogin = professionalSchemaRequest.userData.userLogin;

	professionalSchemaRequest.professionalPrepaidHealths = professional.professional_dto.prepaid_healths?.map(ph => {
		let obj = {prepaidHealthId: ph.id};
		if (ph.prepaidHealthId) obj.id = ph.prepaidHealthId;
		return obj;
	});

	professionalSchemaRequest.professionalSpecialities = professional.professional_dto.specialties?.map(ph => {
		let obj = {specialityId: ph.id};
		if (ph.specialityId) obj.id = ph.specialityId;
		return obj;
	});

	// if (typeof professional.professional_dto.specialties) {
	// 	let specialties = [];
	// 	specialties.push(professional.professional_dto.specialties);
	// 	professional.professional_dto.specialties = specialties;
	// }
	// professionalSchemaRequest.professionalSpecialities = professional.professional_dto.specialties?.map(sp => {
	// 	// console.log(spec);
	// 	// let sp = spec[0];
	// 	console.log(sp);
	// 	let obj = {specialityId: sp.id};
	// 	if (sp.specialityId) obj.id = sp.specialityId;
	// 	return obj;
	// });

	// let specialties = [];
	// if (typeof professional.professional_dto.specialties) {
	// 	specialties.push(professional.professional_dto.specialties);
	// }
	// professional.professional_dto.specialties?.map(sp => {
	// 	// console.log(sp);
	// 	let obj = {specialityId: sp.id};
	// 	// console.log(obj);
	// 	if (sp.specialityId) obj.id = sp.specialityId;
	// 	professionalSchemaRequest.professionalSpecialities.push(obj);
	// 	// console.log(obj);
	// });
	// // console.log(professionalSchemaRequest);
	return professionalSchemaRequest;
};

const professionalPrepaidHealthsIdMapperRs = (p) => {
	return p.map(ph => {
		return `${ph.prepaidHealthId}@${ph.name}`
	});
};

const professionalPrepaidHealthsMapperRs = (p) => {
	return p.map(ph => {
		return {id: ph.prepaidHealthId, prepaidHealthId: ph.id, name: ph.name};
	});
};

const professionalSpecialitiesIdMapperRs = (p) => {
	return p.map(ps => {
		return `${ps.specialityId}@${ps.name}`
	});
};

const professionalSpecialitiesMapperRs = (p) => {
	return p.map(ps => {
		return {id: ps.specialityId, specialityId: ps.id, name: ps.name};
	});
};

const getProfile = async (jwtToken, response, commonHeaders, photoName) => {
	const decodedToken = decode(jwtToken);
	const prw = new ParametricsWrapper();
	let professional = professionalMapperRs(response.data);
	professional.data.id_number = response.data.idNumber ? response.data.idNumber : decodedToken.authid ? decodedToken.authid : '';
	professional.data.active_role = 'PROFESSIONAL';
	// ANULADO POR QUE NO SE USA MAS CHIME
	// try {
	// 	const resChime = await axios.get(
	// 		`${config.api.consultation.CONSULTATION_ENDPOINT_BUSINESS_BY_PROFESSIONAL}/${professional.data.email}`,
	// 		{headers: {commonHeaders, authorization: `Bearer ${jwtToken}`}}
	// 	);
	// 	professional.data.professional_dto.chime_response = `room${resChime.data.id}`;
	// } catch (e) {
	// 	// if(e.response.estatus !== 404) throw e;
	// }
	const phs = await prw.searchPrepaidHealths(jwtToken, response.data.professionalPrepaidHealths);
	professional.data.professional_dto.ids_prepaid_healths = professionalPrepaidHealthsIdMapperRs(phs);
	professional.data.professional_dto.prepaid_healths = professionalPrepaidHealthsMapperRs(phs);

	const sps = await prw.searchSpecialities(jwtToken, response.data.professionalSpecialities);
	professional.data.professional_dto.ids_specialties = professionalSpecialitiesIdMapperRs(sps);
	professional.data.professional_dto.specialties = professionalSpecialitiesMapperRs(sps);

	const prow = new ProfessionalWrapper();
	const photo = await prow.professionalPhoto(jwtToken, response.data.photo !== null ? response.data.photo : photoName);
	professional.data.professional_dto.photo_encode = photo.data.professional_dto.photo_encode;
	const sign_photo = await prow.getProfessionalSignPhoto(jwtToken, response.data.userLogin);
	if (sign_photo?.data){
		professional.data.professional_dto.sign_photo = sign_photo.data.professional_dto.photo_encode;
	} 
	const upw = new UserProfileWrapper();
	const aid = await upw.getUserId(jwtToken, decodedToken.sub !== null ? decodedToken.sub : (professional.data.email !== null ? professional.data.email : ''));
	professional.data.professional_dto.auth_id = aid.id;

	return professional;
};

const getProfileById = async (jwtToken, response, commonHeaders, photoName, id) => {

	const decodedToken = decode(jwtToken);
	const prw = new ParametricsWrapper();
	let professional = professionalMapperRs(response.data);
	professional.data.id_number = response.data.idNumber ? response.data.idNumber : decodedToken.authid ? decodedToken.authid : '';
	professional.data.active_role = 'PROFESSIONAL';
	// ANULADO POR QUE NO SE USA MAS CHIME
	// try {
	// 	const resChime = await axios.get(
	// 		`${config.api.consultation.CONSULTATION_ENDPOINT_BUSINESS_BY_PROFESSIONAL}/${professional.data.email}`,
	// 		{headers: {commonHeaders, authorization: `Bearer ${jwtToken}`}}
	// 	);
	// 	professional.data.professional_dto.chime_response = `room${resChime.data.id}`;
	// } catch (e) {
	// 	// if(e.response.estatus !== 404) throw e;
	// }
	const phs = await prw.searchPrepaidHealths(jwtToken, response.data.professionalPrepaidHealths);
	professional.data.professional_dto.ids_prepaid_healths = professionalPrepaidHealthsIdMapperRs(phs);
	professional.data.professional_dto.prepaid_healths = professionalPrepaidHealthsMapperRs(phs);

	const sps = await prw.searchSpecialities(jwtToken, response.data.professionalSpecialities);
	professional.data.professional_dto.ids_specialties = professionalSpecialitiesIdMapperRs(sps);
	professional.data.professional_dto.specialties = professionalSpecialitiesMapperRs(sps);

	const prow = new ProfessionalWrapper();
	const photo = await prow.professionalPhoto(jwtToken, response.data.photo !== null ? response.data.photo : photoName);
	professional.data.professional_dto.photo_encode = photo.data.professional_dto.photo_encode;
	professional.data.professional_dto.auth_id = id;

	return professional;
};

class ProfessionalWrapper extends Wrapper {
	constructor() {
		super();
	}

	async getProfessional(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return await getProfile(jwtToken, response, {...this.commonHeaders});
		} catch (error) {
			error.message = `Error mdw getProfessional: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async professionalPhoto(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/photo/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {
				status: "OK",
				data: {professional_dto: {...response.data}}
			};
		} catch (error) {
			error.message = `Error mdw professionalPhoto: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getProfessionalSignPhoto(jwtToken, login) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/sign-photo/${login}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {
				status: "OK",
				data: {professional_dto: {...response.data}}
			};
		} catch (error) {
			error.message = `Error mdw getProfessionalSignPhoto: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	};

	async professionalProfile(jwtToken, body) {
		try {
			let response = {};
			if (body.professional_dto.id) {
				response = await axios.put(
					`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/${body.email}`,
					professionalMapperRq(body),
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);

			} else {
				response = await axios.post(
					config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT,
					professionalMapperRq(body),
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);
			}
			const photo = await axios.post(
				`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/upload-photo-b64`,
				{photo_encode: body.professional_dto.photo_encode},
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			if (body.professional_dto?.sign_photo){
				if (typeof body.professional_dto.sign_photo === 'object') {
					let formData = new FormData();
					formData.append("file", body.professional_dto?.sign_photo);
					await axios.post(
						`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/upload-sign-photo?userLoginProfessional=` + body.email,
						formData,
						{headers: {...this.commonFileHeaders, authorization: `Bearer ${jwtToken}`}}
					);
				}
			}
			const pro = await getProfile(jwtToken, response, {...this.commonHeaders}, photo.data.fileUploaded);
			return {status: 'ok', ...pro};
		} catch (error) {
			error.message = `Error mdw professionalProfile: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async professionalProfileMobile(jwtToken, body) {
		try {
			let response = {};
			if (body.professional_dto.id) {
				response = await axios.put(
					`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/${body.email}`,
					professionalMapperRq(body),
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);

			} else {
				response = await axios.post(
					config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT,
					professionalMapperRq(body),
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);
			}
			const photo = await axios.post(
				`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/upload-photo-b64`,
				{photo_encode: body.professional_dto.photo_encode},
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			if (body.professional_dto?.sign_photo){
				await axios.post(
					`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/upload-sign-photo-b64?userLoginProfessional=` + body.email,
					{sign_photo_encode: body.professional_dto?.sign_photo},
					{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
				);
			}
			const pro = await getProfile(jwtToken, response, {...this.commonHeaders}, photo.data.fileUploaded);
			return {status: 'ok', ...pro};
		} catch (error) {
			error.message = `Error mdw professionalProfileMobile: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async putVirtualGuard(jwtToken, id, body) {
		try {
			const decodedToken = decode(jwtToken);
			const response = await axios.patch(
				`${config.api.user_profile.PROFESSIONAL_BS_USER_PROFILE_ENDPOINT}/${decodedToken.sub}`,
				body,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw putVirtualGuard: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getProfessionalAvailable(jwtToken, params) {
		try {
			const response = await axios.get(
				this.concatNotNullParam(config.api.user_profile.PROFESSIONAL_BS_PREPAID_HEALTH_ENDPOINT, params),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			let pro = await getProfile(jwtToken, {data: response.data[0]}, {...this.commonHeaders});

			return {status: "OK", data: [pro.data]};
		} catch (error) {
			error.message = `Error mdw getProfessionalAvailable: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getSelectedProfessionals(jwtToken, params) {
		try {
			const response = await axios.get(
				this.concatNotNullParam(config.api.schedule.SCHEDULE_AVAILABILITY_ENDPOINT, params),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			let professionals = response.data.map(e=>{
				return {
					id: e.professionalLogin,
					name: `${e.firstName} ${e.lastName}`,
					next_date: e.hourFrom,
					professional_dto: {
						photo_encode: e.photo
					},
					specialty: params.specialtyName,
					scheduleId: e.scheduleId

				};
			})
			return {status: "OK", data: professionals};
		} catch (error) {
			error.message = `Error mdw getSelectedProfessionals: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getProfessionalProfile(jwtToken) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/profile`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			const pro = await getProfile(jwtToken, response, {...this.commonHeaders});
			return {status: 'ok', ...pro};
		} catch (error) {
			error.message = `Error mdw getProfessionalProfile: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }

	};

	async getProfessionalProfileById(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/profile/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			const pro = await getProfileById(jwtToken, response, {...this.commonHeaders}, id);
			return {status: 'ok', ...pro};
		} catch (error) {
			error.message = `Error mdw getProfessionalProfileById: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }

	};
}

export default ProfessionalWrapper;
