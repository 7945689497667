import Wrapper from "./wrapper";
import {config} from "../config"
import axios from "axios";
import AtentionTypeEnum from "../model/enums/AtentionTypeEnum";
import PrepaidHealthWrapper from "./prepaid.health.wrapper";
import ProfessionalWrapper from "./professional.wrapper.js";


const consultationWrapperRs = async (jwtToken, data)=>{
	let consultation = {};
	consultation.creation_date = data.createdDate;
	consultation.diagnosis = data.diagnosis;
	consultation.consultationId = data.consultationId;
	consultation.reason = data.reason;
	const professional = await new ProfessionalWrapper().getProfessional(jwtToken,data.professionalLogin);
	consultation.professional_name = professional?.data?.first_name + " " + professional?.data?.last_name;
	consultation.professional_license = professional?.data?.professional_dto?.licence_str;
	consultation.professional_specialties = professional?.data?.professional_dto?.specialties;
	consultation.consultation_type = data.consultationType;
	consultation.affiliate_number = data.affiliateNumber;
	if(data.prepaidHealthCategoryId){
		const prepaidHealth = await new PrepaidHealthWrapper().getPrepaidHealthCategory(jwtToken,data.prepaidHealthCategoryId);
		consultation.prepaid_health_category = {
			name: prepaidHealth?.data?.name,
			prepaid_health: { name: prepaidHealth?.data?.prepaidHealth.name}
		};
	}
	consultation.detail = data?.detail;
	consultation.prescriptions = data?.prescriptions;
	consultation.indications = data?.indications;
	consultation.certificates = data?.certificates;
	consultation.studies = data?.studies;
	consultation.specialties = data?.specialties;
	return consultation;
};

class ConsultationWrapper extends Wrapper {
	constructor() {
		super();
	}

	async createConsultation(jwtToken, body) {
		try {
			let request = {};
			request.attentionTypeParticular = true;

			if (body?.appointment_dto) {
				request.consultationType = AtentionTypeEnum.APPOINTMENT;
				request.appointmentId = body.appointment_dto.id;
				request.affiliateNumber = body.appointment_dto.patient_dto?.affiliate_number;
				request.fullNamePatient = body.appointment_dto.patient_dto?.aliasOrFullName;
				request.patientLogin = body.appointment_dto.patient_dto?.id;

				if (body.appointment_dto.patient_dto?.prepaid_health_category) {
					request.attentionTypeParticular = false;
					request.prepaidHealthCategoryId = body.appointment_dto.patient_dto?.prepaid_health_category?.id;
				}
				request.fullNameProfessional = body.professional_dto?.aliasOrFullName;
				request.professionalLogin = body.professional_dto?.id;
			} else {
				request.consultationType = AtentionTypeEnum.VIRTUAL_GUARD;

				request.affiliateNumber = body?.patient_dto?.affiliate_number;
				request.fullNamePatient = body?.alias;
				request.patientLogin = body?.email;

				if (body?.patient_dto?.attention_type != "Particular") {
					request.attentionTypeParticular = false;
					request.prepaidHealthCategoryId = body?.patient_dto?.prepaid_health_category?.id;
				}

				request.fullNameProfessional = body?.professional_dto?.drName;
				request.professionalLogin = body?.professional_dto?.professionalData?.email;
			}


			request.reason = body?.reason;

			return await axios.post(
				config.api.consultation.CONSULTATION_ENDPOINT_BUSINESS,
				request,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
		} catch (error) {
			error.message = `Error mdw createConsultation: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getConsultationById(jwtToken, id) {

		try {
			return await axios.get(
				`${config.api.consultation.CONSULTATION_ENDPOINT}/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
		} catch (error) {
			error.message = `Error mdw getConsultationById: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async updateConsultation(jwtToken, body) {

		try {
			body.consultationType = body?.appointmentId ? AtentionTypeEnum.APPOINTMENT : AtentionTypeEnum.VIRTUAL_GUARD;
			body.attentionTypeParticular = body?.prepaidHealthCategoryId ? true : false;
			let indications = [];

			body?.medications?.forEach(medication => {
				let indication = {};
				indication.indications = medication.posology;
				indication.name = medication.name;
				indications.push(indication);
			});


			if (body?.other_indications) {
				let otherIndication = {};
				otherIndication.indications = body.other_indications;
				otherIndication.name = "Otras indicaciones";
				indications.push(otherIndication);
			}
			body.indications = indications;
			let response = await axios.patch(
				`${config.api.consultation.CONSULTATION_ENDPOINT_BUSINESS}/${body?.id}`,
				body,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			response.status = 'ok';
			return response;

		} catch (error) {
			error.message = `Error mdw updateConsultation: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getConsultationPatient(jwtToken, params) {
		try {
			const response = await axios.get(
				this.concatNotNullParam(config.api.consultation.CONSULTATION_PATIENT_ENDPOINT, params),
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status: 'ok', data: response.data.length > 0 ? await consultationWrapperRs(jwtToken, response.data[0]): response.data};
		} catch (error) {
			error.message = `Error mdw getConsultationPatient: ${error.message}`;
			//throw error;
			return [];
		}

	};

	async getProfesionalProfile(jwtToken, id) {
		try {
			const response = await axios.get(
				`${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/${id}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getProfessional: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};

	async getRanking(jwtToken, queueId, star) {
		try {
			const response = await axios.get(
				`${config.api.consultation.CONSULTATION_RANKING_ENDPOINT}/${queueId}/${star}`,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return response.data;
		} catch (error) {
			error.message = `Error mdw getRanking: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
	};

	async createAgoraConsultation(jwtToken, body) {
		try {
			let request = {};
			let indications = [];
			request.attentionTypeParticular = true;
			request.consultationType = AtentionTypeEnum.VIRTUAL_GUARD;
			request.patientLogin = body?.familymember;

			if (body?.patient_dto?.attention_type != "Particular") {
				request.attentionTypeParticular = false;
				request.prepaidHealthCategoryId = body?.patient_dto?.prepaid_health_category?.id;
			}

			request.fullNameProfessional = body?.professional_dto?.drName;
			request.professionalLogin = body?.professional_dto?.email;

			request.reason = body?.reason;
			body?.medications?.forEach(medication => {
				let indication = {};
				indication.indications = medication.posology;
				indication.name = medication.name;
				indications.push(indication);
			});
			if (body?.other_indications) {
				let otherIndication = {};
				otherIndication.indications = body.other_indications;
				otherIndication.name = "Otras indicaciones";
				indications.push(otherIndication);
			}

			request.medications = body?.medications
			request.indications = indications;
			request.certificates = body?.certificates;
			request.detail = body?.detail;
			request.diagnosis = body?.diagnosis;
			request.specialties = body?.specialties;
			request.studies = body?.studies;

			return await axios.post(
				config.api.consultation.AGORA_CONSULTATION_ENDPOINT_BUSINESS,
				request,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
		} catch (error) {
			error.message = `Error mdw createAgoraConsultation: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	};


}

export default ConsultationWrapper;
