import Wrapper from "./wrapper";
import axios from "axios";
import {config} from "../config";

class NotificationWrapper extends Wrapper {
    constructor() {
        super();
    };

	async postProfessionalNotifications(jwtToken, userData, queue, event ) {
        try {
			const statusNotification = [];
			if (queue){
				queue.forEach(e=>{
					const data = {
						"entityId" : e.id,
						"event" : event,
						"entity" : {
						  "id" : e.id,
						  "consultationType" : "VIRTUAL_GUARD",
						  "creationDate" : null,
						  "reason" : e.reason,
						  "diagnosis" : e.reason,
						  "patientLogin" : e.familymember,
						  "professionalLogin" : userData?.professional_dto?.userLogin
						},
						"recipients" : [ userData?.phone_number ]
					}
					const responseNotification = axios.post(
						config.api.notification.NOTIFICATION_SERVICE_ENDPINT, data,
						{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
					);
					responseNotification.then((element) => statusNotification.push(element));
				})
				return {status:'OK', data: statusNotification};
			}else {
				return {status:'OK', data: 'Not queue'};
			}
        } catch (error) {
            error.message = `Error mdw postNotifications: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
    }

	async postPacientNotifications(jwtToken, userData, patient, event ) {
        try {
			const data = {
				"entityId" : patient.queueId,
				"event" : event,
				"entity" : {
				  "id" : patient.queueId,
				  "consultationType" : "VIRTUAL_GUARD",
				  "creationDate" : null,
				  "reason" : patient.reason,
				  "diagnosis" : patient.reason,
				  "patientLogin" : patient.familymember,
				  "professionalLogin" : ""
				},
				"recipients" : [ userData?.phone_number, userData?.email ]
			}
			const responseNotification = axios.post(
				config.api.notification.NOTIFICATION_SERVICE_ENDPINT, data,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);

			return {status:'OK', data: responseNotification.data};
			
        } catch (error) {
            error.message = `Error mdw postNotifications: ${error.message}`;
			console.log(error);
			if(error.response.status != 200) {
				return [];
			} else throw error;
        }
    }

    async getNotifications(jwtToken, params) {
        try {
            const response = await axios.get(
	            this.concatNotNullParam(config.api.notification.NOTIFICATION_SERVICE_PATH, params),
                {headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
            );
				let notifications = [];
				response.data.forEach(e=>{
					notifications.push({
						creation_date: e.creationDate,
						description: e.body,
						expiration_date: e.expirationDate,
						expiration_value: null,
						id: e.id,
						mean: e.type,
						priority: e.priority,
						status: e.status,
						subject: e.subject,
						template_name: "appointmentCreatedInbox.html",
						time_unit: "DAYS"
					});
				});
            return {status:'OK', data: notifications};
        } catch (error) {
            error.message = `Error mdw getNotifications: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
    }

    async getNotificationsQuantity(jwtToken, params) {
        try {
            const response = await axios.get(
                this.concatNotNullParam(config.api.notification.NOTIFICATION_QUANTITY_ENDPOINT, params),
                {headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
            );
            return {status: "OK", data:[{quantity: response.data.quantity}]};
        } catch (error) {
            error.message = `Error mdw getNotificationsQuantity: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
    }

    async putNotification(jwtToken, body) {
        try {
            const response = await axios.patch(
                `${config.api.notification.NOTIFICATION_SERVICE_PATH}/${body.id}`,
	            body,
                {headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
            );
            return {status: 'OK'};
        } catch (error) {
            error.message = `Error mdw putNotification: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
    }

	async processNotification(jwtToken, body) {
		try {
			const response = await axios.patch(
				`${config.api.notification.NOTIFICATION_SERVICE_PATH}/batch`,
				body,
				{headers: {...this.commonHeaders, authorization: `Bearer ${jwtToken}`}}
			);
			return {status: 'OK'};
		} catch (error) {
			error.message = `Error mdw putNotification: ${error.message}`;
			console.log(error);
			if(error.response.status == 404) {
				return [];
			} else throw error;
        }
	}
}

export default NotificationWrapper;
