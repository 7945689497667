import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import ConsultationSuccessModal from "../../pages/patient/ModalList/AppointmentSuccess/consultationSuccess";
// import VirtualBackgroundExtension from 'agora-extension-virtual-background';
import { useStateAuthValue } from "../../context/Auth/AuthState";
import { Role } from "../../models/enums";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";
import { LocalVideo } from "./LocalVideo";
import { RemoteVideo } from "./RemoteVideo";
import { ProfVideoButtons } from "./ProfVideoButtons";
import { PatientVideoButtons } from "./PatientVideoButtons";
import { GetProfessionalProfileById } from "../../services/professionalService";
// import { getRandomBackgroundImage } from './videocallUtils/getRandomBackgroundImageUtil';

import "./css/call.css";
import { EventLog } from "../../services/medilinePrepaidService";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

// const extension = new VirtualBackgroundExtension();
// AgoraRTC.registerExtensions([extension]);

export const Call = ({
  rtcProps = {},
  setVideoCall,
  setContainerStyle,
  setMessage,
  setConsultSuccess,
  consultSuccess,
  consultIsSubmitted,
  consultFinishedWithError,
  consultDuration = "",
  patientLeftProfessionalContainerStyle,
  setPatientLeftProfessionalContainerStyle,
  setPatientFinish,
  stompData,
}) => {
  const [{ userData, userToken }] = useStateAuthValue();
  const [callFinished, setCallFinished] = useState(false);
  const currentRole = userData?.active_role;
  const isPatient = currentRole === Role.PATIENT;
  const isProfessional = currentRole === Role.PROFESSIONAL;
  // const randomImage = getRandomBackgroundImage();
  const [profName, setProfName] = useState("");
  const [profLicense, setProfLisence] = useState("");
  const toastDispatch = useToast();

  // let processor = null;
  const [localTracks, setLocalTracks] = useState({
    audioTrack: null,
    videoTrack: null,
  });

  const join = async () => {
    await client.join(
      rtcProps.appId,
      rtcProps.channel,
      rtcProps.token || null,
      rtcProps.uid || null
    );
  };

  useEffect(() => {
    if (stompData?.professionalId && isPatient) {
      const getProfData = async () => {
        await GetProfessionalProfileById(
          userToken,
          stompData?.professionalId
        ).then((res) => {
          setProfName(res.data.first_name + " " + res.data.last_name);
          setProfLisence(res.data.professional_dto.licence_str);
        });
      };
      getProfData();
    }
  }, [stompData]);

  const startVideo = () => {
    AgoraRTC.createCameraVideoTrack({
      encoderConfig: process.env.REACT_APP_VIDEO_ENCODERCONFIG || "120p",
    })
      .then((videoTrack) => {
        setLocalTracks((tracks) => ({
          ...tracks,
          videoTrack,
        }));
        client.publish(videoTrack);
        videoTrack.play("local");
      })
      .catch((e) => {
        setMessage("Ocurrió un error al conectarse al video: ", e);
      });
    // setBackground();
  };

  const startAudio = () => {
    AgoraRTC.createMicrophoneAudioTrack({
      encoderConfig:
        process.env.REACT_APP_AUDIO_ENCODERCONFIG || "speech_low_quality",
    })
      .then((audioTrack) => {
        setLocalTracks((tracks) => ({
          ...tracks,
          audioTrack,
        }));
        client.publish(audioTrack);
      })
      .catch((e) => {
        setMessage("Ocurrió un error al conectarse al audio: ", e);
      });
  };

  const stopVideo = () => {
    localTracks.videoTrack.close();
    localTracks.videoTrack.stop();
    client.unpublish(localTracks.videoTrack);
  };

  const stopAudio = () => {
    localTracks.audioTrack.close();
    localTracks.audioTrack.stop();
    client.unpublish(localTracks.audioTrack);
  };

  const leaveVideoCall = async () => {
    stopVideo();
    stopAudio();
    client.leave();
    await EventLog(userToken, "suscribing videotrack", isProfessional ? "profesional" : "paciente", stompData);
    setVideoCall(false);
  };

  async function startOneToOneVideoCall() {
    client.enableDualStream();
    join().then(() => {
      startVideo();
      startAudio();
      client.on("network-quality", (stats) => {
        if (
          stats.uplinkNetworkQuality >= 5 ||
          stats.downlinkNetworkQuality >= 5
        ) {
          const error = "Conexión de red inestable";
          showToast(toastDispatch, error, ToastType.WARNING, ToastType.WARNING);
        }
      });
      client.on("user-published", async (user, mediaType) => {
        if (client._users.length > 1) {
          client.leave();
          if (process.env.REACT_APP_DEBUG === "true") {
            const error = "Please Wait, Room is Full";
            showToast(toastDispatch, error, ToastType.ERROR, ToastType.ERROR);
          }
          return;
        }

        await client.subscribe(user, mediaType);
        await EventLog(userToken, "suscribing videotrack", isProfessional ? "profesional" : "paciente", stompData)
        if (mediaType === "video") {
          const remoteVideoTrack = user.videoTrack;
          remoteVideoTrack.play("remote");
        }

        if (mediaType === "audio") {
          user.audioTrack.play();
        }
      });
    });

    client.on("user-left", async () => {
      if (isPatient) {
        setCallFinished(true);
      } else {
        setPatientFinish(true);
        setPatientLeftProfessionalContainerStyle({ display: "none" });
        setContainerStyle({ display: "inline-block" });
        setMessage(
          "El paciente se desconecto, por favor, complete el registro de consulta antes de finalizar la llamada."
        );
      }
    });

    client.on("connection-state-change", (currSta, revState) => {
      if (
        (currSta === "RECONNECTING" && revState === "CONNECTED") ||
        (currSta === "DISCONNECTED" && revState === "CONNECTING")
      ) {
        join();
      }
    });
  }

  // Initialization
  // async function getProcessorInstance() {
  //   if (!processor && localTracks.videoTrack) {
  //     // Create a VirtualBackgroundProcessor instance
  //     processor = extension.createProcessor();

  //     try {
  //       // Initialize the extension and pass in the URL of the Wasm file
  //       await processor.init('../../assets/wasms/agora-wasm.wasm');
  //     } catch (e) {
  //       if (process.env.REACT_APP_DEBUG === 'true') {
  //         const errorMessage =
  //           'Error:  ~ file: Call.js:167 ~ getProcessorInstance ~ error' + e;
  //         showToast(
  //           toastDispatch,
  //           errorMessage,
  //           ToastType.ERROR,
  //           ToastType.ERROR
  //         );
  //       }
  //       return null;
  //     }
  //     // Inject the extension into the video processing pipeline in the SDK
  //     localTracks.videoTrack
  //       .pipe(processor)
  //       .pipe(localTracks.videoTrack.processorDestination);
  //   }
  //   return processor;
  // }

  // const setBackground = async () => {
  //   if (!processor && localTracks.videoTrack) {
  //     let processor = await getProcessorInstance();
  //     const imgElement = document.createElement('img');
  //     if (isPatient) {
  //       try {
  //         processor.setOptions({
  //           type: 'blur',
  //           blurDegree: 3,
  //         });
  //         await processor.enable();
  //       } catch (error) {
  //         if (process.env.REACT_APP_DEBUG === 'true') {
  //           const errorMessage =
  //             'Error:  ~ file: Call.js:191 ~ setBackground ~ error' + error;
  //           showToast(
  //             toastDispatch,
  //             errorMessage,
  //             ToastType.ERROR,
  //             ToastType.ERROR
  //           );
  //         }
  //       }
  //     }
  //     if (isProfessional) {
  //       imgElement.onload = async () => {
  //         try {
  //           processor.setOptions({ type: 'img', source: imgElement });
  //           await processor.enable();
  //         } catch (error) {
  //           if (process.env.REACT_APP_DEBUG === 'true') {
  //             const errorMessage =
  //               'Error:  ~ file: Call.js:208 ~ imgElement.onload= ~ error' +
  //               error;
  //             showToast(
  //               toastDispatch,
  //               errorMessage,
  //               ToastType.ERROR,
  //               ToastType.ERROR
  //             );
  //           }
  //         }
  //       };
  //       imgElement.src = randomImage;
  //       imgElement.crossOrigin = 'anonymous';
  //     }
  //   }
  // };

  useEffect(() => {
    startOneToOneVideoCall();
  }, []);

  useEffect(() => {
    if (localTracks.videoTrack) {
      // setBackground();
    } else {
      // processor = null;
    }
  }, [localTracks.videoTrack]);

  return (
    <>
      <div
        className="call-container"
        style={patientLeftProfessionalContainerStyle}
      >
        <div className="p-2 rounded shadow-lg position-relative">
          {!isProfessional && (
            <div
              style={{
                position: "absolute",
                zIndex: "999",
                borderRadius: "5px 0px 20px 0",
                color: "white",
                backgroundColor: "#002C54",
                width: "21%",
              }}
            >
              <div
                style={{
                  padding: "10px",
                }}
              >
                <p
                  style={{
                    margin: "0",
                  }}
                >
                  Estás siendo atentido por el/la{" "}
                  <span style={{ fontWeight: "bold" }}>Dr/a. {profName}</span>.
                </p>
                <p
                  style={{
                    margin: "0",
                  }}
                >
                  Mat: {profLicense}
                </p>
              </div>
            </div>
          )}
          <LocalVideo />
          <RemoteVideo isProfessional={isProfessional} profName={profName} />
          {isPatient && (
            <PatientVideoButtons
              setMessage={setMessage}
              callFinished={callFinished}
              actions={{
                startAudio,
                stopAudio,
                startVideo,
                stopVideo,
                leaveVideoCall,
                startOneToOneVideoCall,
                // setBackground,
              }}
            />
          )}
          {isProfessional && (
            <ProfVideoButtons
              setVideoCall={setVideoCall}
              setMessage={setMessage}
              consultIsSubmitted={consultIsSubmitted}
              consultFinishedWithError={consultFinishedWithError}
              consultDuration={consultDuration}
              actions={{
                startAudio,
                stopAudio,
                startVideo,
                stopVideo,
                leaveVideoCall,
                startOneToOneVideoCall,
                // setBackground,
              }}
            />
          )}
        </div>
      </div>
      <ConsultationSuccessModal
        open={consultSuccess}
        setOpen={setConsultSuccess}
        handleClose={() => {
          setConsultSuccess(!consultSuccess);
        }}
        title="¡Felicidades, tu consulta ha finalizado con éxito!"
      />
    </>
  );
};
