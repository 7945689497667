import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
   Button,
   CircularProgress,
   Stepper,
   Step,
   StepLabel,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';

// Patient Data
import {
   patientInitialValues,
} from './FormModels/patient';
import famMemberValidation from "./FormModels/patient/famMemberValidation/famMemberValidation";
import famMemberModel from "./FormModels/patient/famMemberValidation/famMemberModel";
import AddressForm from './Forms/patient/AddressForm';
import MedicalRecordForm from './Forms/patient/MedicalRecordForm';
import {PatientProfile} from "../../services/patientService";

// Shared Data
import PersonalInformationPatientForm from './Forms/patient/PersonalInformationPatientForm';

import ReviewProfile from './ReviewProfile';
import HeaderImage from '../../assets/img/miPerfil.svg';
import { AttentionTypeEnum, Role } from '../../models/enums';
import { ParentalRelationshipType } from '../../utils/ParentalRelationshipsUtil';
import { showToast, ToastType } from '../../utils/ToastUtil';
import { GetUserProfile } from '../../services/apiService';
import { GetUserBySession } from '../../services/authService';
import { useToast } from '../../context/Toast/ToastProvider';
import { useStateAuthValue } from '../../context/Auth/AuthState';
import {
   fileToBase64,
} from '../../utils/FormUtil';

const patientSteps = [
   'Información Personal',
   'Dirección',
   'Antecedentes Médicos',
   'Datos Ingresados',
];


const newFamMemberRegistration = (props) => {
   const editMemberData= props?.location?.state?.memberData ? props.location.state.memberData : false;
   const relationship= props?.location?.state?.relationship ? props.location.state.relationship : {};
   if(editMemberData) editMemberData.relationship_to_titular = relationship;
   const [isEditionOfMember, setIsEditionOfMember] = useState(false);
   const [particularPrepaidHealth, setParticularPrepaidHealth] = useState({});
   const [particularPrepaidHealthCategory, setParticularPrepaidHealthCategory] = useState({});
   const history = useHistory();
   const [{ userData, userToken }, dispatch] = useStateAuthValue();
   const currentRole = userData?.active_role;
   const isPatient = currentRole === Role.PATIENT;
   const toastDispatch = useToast();
   const [titularFormValues, setTitularFormValues] = useState([]);
   const [activeStep, setActiveStep] = useState(0);
   const [validationSchema, setValidationSchema] = useState({});
   const [formModel, setFormModel] = useState({});
   const [steps, setSteps] = useState([]);
   const currentValidationSchema = validationSchema[activeStep];
   const lastStep = steps.length - 1;
   const isLastStep = activeStep === lastStep;

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   useEffect(() => {
      if (isPatient) {
         setSteps(patientSteps);
         setFormModel(famMemberModel);
         setValidationSchema(famMemberValidation);
      }
   }, [isPatient]);

   useEffect(() => {
      if (userData?.id_number) {
         if (isPatient) {
            setTitularFormValues(patientInitialValues);
            if(editMemberData){
               setIsEditionOfMember(true);
               setTitularFormValues({
                  ...editMemberData,
                  phone_number: userData.phone_number,
                  address_dto: userData.address_dto 
               })
            } else {
               setTitularFormValues({
                  ...titularFormValues,
                  alias : "",
                  first_name : "",
                  last_name : "",
                  id_number : "",
                  relationship_to_titular : "",
                  birth_date : new Date(),
                  sex_str : "",
                  patient_dto : {
                     pre_existing_disease: [],
                     frequent_medication: [],
                     surgeries: [],
                     size: '',
                     weight: '',
                     allergies: '',
                     family_history: '',
                     vaccines: '',
                     gynecological_data: '',
                     attention_type: '',
                     affiliate_number: '',
                     study: [],
                  },
                  phone_number: userData.phone_number,
                  address_dto: userData.address_dto 
               });
            }
            
         } 
         
   }}, [userData, isPatient, currentRole]);

   useEffect(() => {
      if (!userToken) {
         return;
      }
     const errorMessage = "Ocurrió un error buscando el perfil del usuario";
     GetUserProfile(userToken, showError)
       .then((response) => {
         if (response.status === "ok") {
           const userAuth = GetUserBySession();
           response.data.email =
             response?.data?.email || userAuth?.email || null;
           response.data.username =
             response?.data?.username || userAuth?.nickname || null;
           dispatch({
             type: "SAVE_USER_INFO",
             userAuth,
             userData: response?.data
           });
           dispatch({ type: "SAVE_SESSION" });
         } else {
           showError(errorMessage);
         }
       })
       .catch(() => showError(errorMessage));
   }, [userToken]);

   const renderStepContent = (step) => {
      switch (step) {
         case 0:
            if (isPatient) {
               return <PersonalInformationPatientForm formModel={formModel} isFam={true} particularPrepaidHealth={particularPrepaidHealth} setParticularPrepaidHealth={setParticularPrepaidHealth} particularPrepaidHealthCategory={particularPrepaidHealthCategory} setParticularPrepaidHealthCategory={setParticularPrepaidHealthCategory} isEditionOfMember={isEditionOfMember}/>;
            } else {
               return <div>No encontrado</div>;
            }
         case 1:
            if (isPatient) {
               return <AddressForm formModel={formModel} isFam={true}/>;
            } else {
               return <div>No encontrado</div>;
            }
         case 2:
            if (isPatient) {
               return <MedicalRecordForm formModel={formModel} isFam={true}/>;
            } else {
               return <div>No encontrado</div>;
            }
         case 3:
            if (isPatient) {
               return <ReviewProfile currentRole={currentRole} isFam={true}/>;
            } else {
               return <div>No encontrado</div>;
            }
         default:
            return <div>No encontrado</div>;
      }
   };

   const submitForm = async (values, actions) => {
      const errorMessage = 'Ocurrió un problema con la adición del miembro';
      const successMessage = '¡Adición de miembro familiar realizado con éxito con éxito!';
      let familyMemberParams = { ...values };

      if(familyMemberParams?.anotherMedication && familyMemberParams?.anotherMedication?.length > 0){
         familyMemberParams.patient_dto.frequent_medication = [
            ...familyMemberParams.patient_dto.frequent_medication,
            {
               frequentMedicationId: uuidv4(),
               id: uuidv4(),
               name: familyMemberParams.anotherMedication
            }
         ];
      }

      if (familyMemberParams.patient_dto?.study && familyMemberParams.patient_dto?.study?.length !== 0){
         let newPatientStudys = [];
         familyMemberParams.patient_dto.study.map(async (element) => {
            if (element instanceof File) {
               let newPatientStudy = {
                  mimetype: element.type,
                  filename: element.name,
                  size: element.size,
                  base64: await fileToBase64(
                     element,
                  )
               }
               newPatientStudys.push(newPatientStudy);
            } else {
               newPatientStudys.push(element);
            }
         })
         familyMemberParams.patient_dto.study = newPatientStudys;
      }

      ParentalRelationshipType.map((p) => {
         if(p.value === familyMemberParams.relationship_to_titular) familyMemberParams.relationship_to_titular = p;
       });
      let prevFamilyState = userData.patient_dto.family_group;
      ///check if it's editing prev member or adding new one
      const i = prevFamilyState.findIndex(_item => _item.id_number === familyMemberParams.id_number);
      if (i > -1) prevFamilyState[i] = familyMemberParams; 
      else prevFamilyState.push(familyMemberParams);
      const newUserData = {...userData, patient_dto: {...userData.patient_dto, family_group: [...prevFamilyState]}}
      await PatientProfile(newUserData, userToken, showError)
         .then(() => {
            newUserData.patient_dto.full_profile = true
            dispatch({
               type: 'SAVE_USER_INFO',
               userData: newUserData,
            });
            dispatch({ type: 'SAVE_SESSION' });
            showToast(toastDispatch, successMessage, ToastType.SUCCESS);
            history.push('/grupo-familiar');
         })
         .catch(() => {
            showError(errorMessage);
            actions.setSubmitting(false);
         }); 
      setActiveStep(lastStep); 
   }; 

   const handleSubmit = (values, actions) => {
      if (isLastStep) {
         submitForm(values, actions);
      } else {
         if(!(values.patient_dto.attention_type === AttentionTypeEnum.HEALTHINSURANCE && values.patient_dto.prepaid_health_category?.prepaid_health && !values.patient_dto.prepaid_health_category?.id)){
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
         } else {
            actions.setTouched({});
            actions.setSubmitting(false);
         }
      }
   };

   const handleBack = () => {
      setActiveStep(activeStep - 1);
   };

   return (
      <div className="full-registration">
         <div className="header">
            <img src={HeaderImage} alt="Registro Completo" />
            <h2>Agregar familiar</h2>
         </div>

         <div className="column">
            <Stepper alternativeLabel activeStep={activeStep}>
               {steps.map((label) => (
                  <Step key={label} className="step">
                     <StepLabel className="step">{label}</StepLabel>
                  </Step>
               ))}
            </Stepper>
            {userData?.id_number && steps.length && (
               <div className="container">
                  <Formik
                     initialValues={{...titularFormValues, "anotherMedication":""}}
                     validationSchema={currentValidationSchema}
                     onSubmit={handleSubmit}
                     enableReinitialize
                     validateOnMount
                  >
                     {({ isSubmitting }) => (
                        <Form id="fullRegistrationForm">
                           {renderStepContent(activeStep)}

                           <div className="btn-wrapper">
                              {activeStep !== 0 && (
                                 <Button
                                    disabled={isSubmitting}
                                    onClick={handleBack}
                                    variant="outlined"
                                    className={`btn btn--back ${
                                       isSubmitting && 'btn--disabled'
                                    }`}
                                 >
                                    Volver
                                 </Button>
                              )}
                              <>
                                 <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    endIcon={
                                       isSubmitting && (
                                          <CircularProgress size={24} />
                                       )
                                    }
                                    className={`btn ${
                                       isLastStep ? 'btn--save' : 'btn--next'
                                    } ${isSubmitting && 'btn--disabled'}`}
                                 >
                                    {isLastStep ? 'Finalizar' : 'Siguiente'}
                                 </Button>
                              </>
                           </div>
                        </Form>
                     )}
                  </Formik>
               </div>
            )}
         </div>
      </div>
   );
};

export default newFamMemberRegistration;
