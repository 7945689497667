import NotificationWrapper from '@mediline/mediline-api-middleware/src/wrappers/notification.wrapper'

const nw = new NotificationWrapper();

const getNotifications = async (params, token, displayError = null) => nw.getNotifications(token, params, displayError);

const getNotificationsQuantity = async (params, token, displayError = null) => nw.getNotificationsQuantity(token, params, displayError);

const putNotification = async (body, token, displayError = null) => nw.putNotification(token, body, displayError);

const processNotification = async (body, token, displayError = null) => nw.processNotification(token, body, displayError);

const postProfessionalNotifications = async (token, professionalLogin, queue, event) => nw.postProfessionalNotifications(token, professionalLogin, queue, event)

const postPatientNotifications = async (token, userData, patient, event) => nw.postPacientNotifications(token, userData, patient, event)

export {getNotifications, getNotificationsQuantity, putNotification, processNotification, postProfessionalNotifications, postPatientNotifications};
