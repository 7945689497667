import React, { useState, useRef } from 'react';
import PatientData from './Data';
import PatientFilter from './Filter';
import PatientResult from './Result';
import ConsultationSuccessModal from './ModalList/AppointmentSuccess/consultationSuccess';
import VideoCallPatient from '../../components/AgoraVideoCall/videoCallPatient';
import ProfConsultFilterReducer, {
  initialStateProf,
} from '../../context/profConsultFilterReducer';
import {CheckPermission} from "../../utils/CheckDesktopPermissions";
import { ProfConsultFilterState } from '../../context/profConsultFilterState';
import dialtone from "../../assets/notifications/dialtone.mp3";

const Patient = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const [askPermission, setAskPermission] = useState(false);
  const [videoCall, setVideoCall] = useState(false);
  const [consultSuccess, setConsultSuccess] = useState(false);
  const [virtualGuardData, setVirtualGuardData] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [queue, setQueue] = useState(null);
  const audioElement = useRef(new Audio(dialtone));

  const stopRingCall = () => {
    audioElement.current.pause();

      audioElement.current.removeEventListener(
        "ended",
        () => {
          audioElement.current.pause();
        },
        false
      );
  }

  return (
    <ProfConsultFilterState
      initialState={initialStateProf}
      reducer={ProfConsultFilterReducer}
    >
      <CheckPermission setHasPermission={setHasPermission} askPermission={askPermission} setAskPermission={setAskPermission}/>
      {videoCall ? (
        <VideoCallPatient
          setQueue={setQueue}
          consultSuccess={consultSuccess}
          setConsultSuccess={setConsultSuccess}
          videoCallData={virtualGuardData}
          setVideoCall={setVideoCall}
          setMessage={setToastMessage}
        />
      ) : (
        <div className="patient">
          <div className="filter-result">
            <PatientFilter
              setConsultSuccess={setConsultSuccess}
              setVideoCallData={setVirtualGuardData}
              setVideoCall={setVideoCall}
              toastMessage={toastMessage}
              stopRingCall={stopRingCall}
              hasPermission={hasPermission}
              setAskPermission={setAskPermission}
            />
            <PatientResult stopRingCall={stopRingCall} audioElement={audioElement}/>
          </div>
          <div className="data">
            <PatientData />
          </div>
        </div>
      )}
      <ConsultationSuccessModal
        queue={queue}
        open={consultSuccess}
        setOpen={setConsultSuccess}
        handleClose={() => {
          setConsultSuccess(!consultSuccess);
        }}
        title="¡Felicidades, tu consulta ha finalizado con éxito!"
      />
    </ProfConsultFilterState>
  );
};

export default Patient;
