import React from "react";
import { HISTOY_CLINIC } from "../../../../../conf/appSetting";
import { Button } from "@material-ui/core";

const Patient = ({ patient }) => {
  const openHistoryClinic = () => {
    window.open(
      HISTOY_CLINIC.base + `${patient?.familymember}?showheader=true`,
      "historia clinica",
      "toolbar=0, menubar=0, resizable=yes, location=no, height=650, width=1000"
    );
  };

  return (
    <div className="accordion-patient">
      <div className="detail-wrapper">
        <div className="detail">
          <span className="data">
            Cobertura: {`${patient?.patient_dto?.prepaid_health_category?.prepaid_health?.name || ''} ${
                                 patient?.patient_dto?.prepaid_health_category?.name || '-'}`}
          </span>
        </div>
        <div className="detail">
          <span className="data">
            N° Afiliado: {patient?.patient_dto?.affiliate_number || '-'}
          </span>
        </div>
      </div>
      <Button className="button-hc" onClick={() => openHistoryClinic()}>
        Historia Clínica
      </Button>
    </div>
  );
};

export default Patient;
