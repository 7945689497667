import { Role, AttentionTypeEnum } from '../models/enums';
import DefaultImage from '../../src/assets/img/user.svg';
import { Image } from '@material-ui/icons';
import { PictureAsPdf } from '@material-ui/icons';
import { Description } from '@material-ui/icons';
import React from "react";

// Helps to render strings 'Sí'/'No' based on boolean received.
export const booleanToString = (bool = false) => (bool ? 'Sí' : 'No');

// Initializes the values based on user role
export const initialValuesByRole = (role, userData) => {
   if (!userData.first_name) userData.first_name = '';
   if (!userData.last_name) userData.last_name = '';
   if (!userData.sex_str) userData.sex_str = '';
   userData.birth_date = userData.birth_date
      ? new Date(userData.birth_date)
      : null;
   if (!userData.phone_number) userData.phone_number = '';

   // Patient initialValues
   if (role === Role.PATIENT) {
      const patientDto = {
        ...userData.patient_dto,
        size: String(userData.patient_dto.size).includes(".")
          ? userData.patient_dto.size
          : String(userData.patient_dto.size).slice(0, 1) +
            "," +
            String(userData.patient_dto.size).slice(1),
      };
      const addressDto = userData.address_dto || {};
      addressDto.principal = true;

      if (
         patientDto.ids_pre_existing_disease &&
         patientDto.pre_existing_disease
      ) {
         patientDto.ids_pre_existing_disease = patientDto.pre_existing_disease;
      }
      if (
         patientDto.ids_frequent_medication &&
         patientDto.frequent_medication
      ) {
         patientDto.ids_frequent_medication = patientDto.frequent_medication;
      }
      if (patientDto.ids_surgery && patientDto.surgeries) {
         userData.patient_dto.ids_surgery = userData.patient_dto.surgeries;
      }

      if (!addressDto?.id) addressDto.id = null;
      if (!addressDto?.street) addressDto.street = '';
      if (!addressDto?.number) addressDto.number = '';
      if (!addressDto?.number_department) addressDto.number_department = '';
      if (!addressDto?.floor) addressDto.floor = '';
      if (!addressDto?.postal_code) addressDto.postal_code = '';
      if (!addressDto?.between_streets) addressDto.between_streets = '';
      if (!addressDto?.city) addressDto.city = '';
      if (!addressDto?.country) addressDto.country = '';
      if (!addressDto?.province) addressDto.province = '';

      userData.address_dto = addressDto;
      userData.patient_dto = patientDto;

      // TODO: Remove this when Backend delete the "old ids" data from model
      if (!patientDto?.ids_pre_existing_disease)
         patientDto.ids_pre_existing_disease = [];
      // TODO: Remove this when Backend delete the "old ids" data from model
      if (!patientDto?.ids_frequent_medication)
         patientDto.ids_frequent_medication = [];
      // TODO: Remove this when Backend delete the "old ids" data from model
      if (!patientDto?.ids_surgery) patientDto.ids_surgery = [];
      if (!patientDto?.pre_existing_disease)
         patientDto.pre_existing_disease = [];
      if (!patientDto?.frequent_medication) patientDto.frequent_medication = [];
      if (!patientDto?.surgeries) patientDto.surgeries = [];
      if (!patientDto?.size) patientDto.size = '';
      if (!patientDto?.weight) patientDto.weight = '';
      if (!patientDto?.allergies) patientDto.allergies = '';
      if (!patientDto?.family_history) patientDto.family_history = '';
      if (!patientDto?.vaccines) patientDto.vaccines = '';
      if (!patientDto?.gynecological_data) patientDto.gynecological_data = '';
      if (patientDto?.prepaid_health_category && patientDto?.prepaid_health_category.name !== 'PARTICULAR') {
         patientDto.attention_type = AttentionTypeEnum.HEALTHINSURANCE;
      } else if (
         !patientDto?.prepaid_health_category ||
         patientDto?.prepaid_health_category.name === 'PARTICULAR'
      ) {
         patientDto.attention_type = AttentionTypeEnum.PARTICULAR;
      } else {
         patientDto.attention_type = '';
      }
      if (!patientDto?.affiliate_number) patientDto.affiliate_number = '';
   }

   // Professional initialValues
   if (role === Role.PROFESSIONAL) {
      const professionalDto = userData.professional_dto;

      if (!professionalDto?.photo_encode) professionalDto.photo_encode = '';
      if (!professionalDto?.sign_photo) professionalDto.sign_photo = '';
      if (professionalDto.ids_specialties && professionalDto.specialties) {
         professionalDto.ids_specialties = professionalDto.specialties;
      }
      if (
         professionalDto.ids_prepaid_healths &&
         professionalDto.prepaid_healths
      ) {
         professionalDto.ids_prepaid_healths = professionalDto.prepaid_healths;
      }
      if (!professionalDto?.licence_str) professionalDto.licence_str = '';
      if (!professionalDto?.type_licence_str)
         professionalDto.type_licence_str = '';
      // TODO: Remove this when Backend delete the "old ids" data from model
      if (!professionalDto?.ids_specialties)
         professionalDto.ids_specialties = [];
      if (!professionalDto?.specialties) professionalDto.specialties = [];
      if (!professionalDto?.academic_training)
         professionalDto.academic_training = '';
      if (!professionalDto?.attention_particular)
         professionalDto.attention_particular = false;
      // TODO: Remove this when Backend delete the "old ids" data from model
      if (!professionalDto?.ids_prepaid_healths)
         professionalDto.ids_prepaid_healths = [];
      if (!professionalDto?.prepaid_healths)
         professionalDto.prepaid_healths = [];
   }

   return userData;
};

// Helps to get the selected options id
export const optionsToIds = (options) => {
   let ids = [];
   if (options?.length) {
      ids = options.map((op) => op.id);
   }
   return ids;
};

export const filename = (files) => {
   let response = '';
   if (files?.length) {
      response += files.map((op) => op.filename ? op.filename : op.name).join(', ');
   } else {
      response = 'Ninguna';
   }
   return response
}

// Helps to render the name of each option selected
export const renderOptions = (options, anotherValue = false) => {
   let response = '';
   if (options?.length) {
      response += options.map((op) => op.name).join(', ');
   } 
   if(anotherValue){
      response = response === "" ? anotherValue : `${response}, ${anotherValue}`;
   }
   if(!options?.length && !anotherValue) {
      response = 'Ninguna';
   }

   return response;
};

// Helps to translate the value according it name
export const valueToName = (list, originalValue) =>
   {
      const value = list.find((element) => element.value === originalValue)
      if(value?.name){
         return value.name;
      }

   }

// For inputs with only letters, accents and special caracters
export const stringRegex =
   /[a-zA-ZÁÉÍÓÚáéíóú\u00f1\u00d1\u00fa\u00da]+(\s*[a-zA-ZÁÉÍÓÚáéíóú\u00f1\u00d1\u00fa\u00da]*)*[a-zA-ZÁÉÍÓÚáéíóú\u00f1\u00d1\u00fa\u00da]*$]*$/; //NOSONAR

export const removeIndexFromList = (index, list, setList) => {
   const newList = [...list];
   newList.splice(index, 1);
   setList(newList);
   return newList;
};

// Helps get param to URL
export const getUrlParam = (name) => {
   var param;
   if (window.location?.search) {
      const qs = new URLSearchParams(window.location.search);
      if (qs) {
         param = qs.get(name);
      }
   }
   return param;
};

// Helps transform a File to base64
export const fileToBase64 = (file) => {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
   });
};

// Gets src from Image file, if it's not an image returns a default image url
export const srcFromFile = (imageFile) => {
   if (fileIsImage(imageFile)) {
      return URL.createObjectURL(imageFile);
   } else if (typeof imageFile === 'string') {
      return `data:image/png;base64,${imageFile}`;
   } else {
      return DefaultImage;
   }
};

// Helps detect type of file
export const fileIsImage = (file) => {
   const types = ['image/jpg', 'image/jpeg', 'image/png'];
   return types.includes(file?.type);
};

export const iconsFile = (type) => {
   if (type?.indexOf('image') !== -1) {
      return <Image/>
   } else if ( type?.indexOf('pdf') !== -1 ) {
      return <PictureAsPdf/>
   } else {
      return <Description/>
   }
 }

export const handleDownload = (fileContentBase64, fileName, mimeType = 'application/octet-stream') => {
   const byteCharacters = atob(fileContentBase64);
   const byteNumbers = new Array(byteCharacters.length);

   for (let i = 0; i < byteCharacters.length; i++) {
     byteNumbers[i] = byteCharacters.charCodeAt(i);
   }

   const byteArray = new Uint8Array(byteNumbers);
   const blob = new Blob([byteArray], { type: mimeType });
   const url = URL.createObjectURL(blob);
   const a = document.createElement('a');
   a.href = url;
   a.download = fileName;
   document.body.appendChild(a);
   a.click();
   document.body.removeChild(a);
   URL.revokeObjectURL(url);
};

// Helps to get the data
export const getValuePlain = (value) => {
   return value ? value : '-';
};

//calculate age from date
export const getAge = (fecha) => {
   let today = new Date();
   let date = new Date(fecha);
   let age = today.getFullYear() - date.getFullYear();
   let diferenciaMeses = today.getMonth() - date.getMonth();
   if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && today.getDate() < date.getDate())
   ) {
      age--;
   }
   return age;
};
