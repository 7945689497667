import './styles/main.scss';
import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import GmailCallback from './components/GmailCallback';
import AuthLayout from './components/Layout/AuthLayout';
import EmptyLayout from './components/Layout/EmptyLayout';
import FamilyGroup from "./components/FamilyGroup/index";
import LayoutRoute from './components/Layout/LayoutRoute';
import AuthReducer, { initialState } from './context/Auth/AuthReducer';
import { AuthState } from './context/Auth/AuthState';
import ToastProvider from './context/Toast/ToastProvider';
import VirtualGuardProvider from './context/VirtualGuard/VirtualGuardProvider';
import FrequentQuestion from './pages/frequentQuestions';
import FullRegistration from './pages/fullRegistration';
import NeaFamilyMember from "./pages/fullRegistration/newFamMemberRegistration";
import Help from './pages/help';
import Home from './pages/home';
import Layout from './pages/layout';
import Notifications from './pages/notifications';
import Patient from './pages/patient';
import Prescription from './pages/patient/Prescriptions';
import PatientVideoCall from './pages/patient/VideoCall';
import MyOrders from '../src/pages/patient/myOrders';
import Professional from './pages/professional';
import AppointmentSchedule from './pages/professional/appointmentSchedule';
import Liquidation from './pages/professional/Liquidation';
import TimeAvailabilitySchedule from './pages/professional/timeAvailabilitySchedule';
import ProfessionalVideoCall from './pages/professional/VideoCall';
import TermsAndConditions from './pages/termsAndConditions';
import HistoryClinic from './pages/historyClinic';
import MyPlan from './pages/myPlan/myPlanModal';
import { sessionGet } from './services/authService';
import MedilineTheme from './theme/MedilineTheme';
import Authpage, { STATE_LOGIN, STATE_SIGNUP, STATE_ACTIVATE, STATE_CREATED, STATE_RECOVER, STATE_RECOVER_FINISH, STATE_RECOVER_MAIL_SENT, STATE_RESETED_PASS, STATE_ACTIVATE_CREATE_NEW_PASS } from './views/account/AuthPage';
import PasswordUpdate from './components/PasswordUpdate';
import HistoryClinicDetail from './pages/historyClinic/HistoryClinicDetail';
import MyAppointments from './pages/patient/MyAppointments';
import { Role } from './models/enums';
import { ProfessionalLogState } from './context/profesionalPage/professionalLog/professionalLogState';
import ProfessionalLogReducer, { professionalLogInitialState } from './context/profesionalPage/professionalLog/professionalLogReducer';
import { ProfessionalLogHook } from './pages/professional/professionalLogHook';
const App = () => {
   const logged = sessionGet();

   return (
     <AuthState initialState={initialState} reducer={AuthReducer}>
       <ProfessionalLogState  initialState={professionalLogInitialState} reducer={ProfessionalLogReducer}>
         <ThemeProvider theme={MedilineTheme}>
            <CssBaseline />
            <ToastProvider>
               <BrowserRouter>
                  <Switch>
                     <LayoutRoute
                        exact
                        path="/login"
                        publicRoute="true"
                        loggedIn={logged}
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage {...props} authState={STATE_LOGIN} />
                        )}
                     />
                     
                     <LayoutRoute
                        exact
                        publicRoute="true"
                        loggedIn={logged}
                        path="/signup/:id_token?"
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage
                              {...props}
                              authState={STATE_SIGNUP}
                              roleAssigned={Role.PATIENT}
                           />
                        )}
                     />
                     <LayoutRoute
                        exact
                        publicRoute="true"
                        loggedIn={logged}
                        path="/recuperar-clave"
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage {...props} 
                           authState={STATE_RECOVER} />
                        )}
                     />
                     
                     <LayoutRoute
                        exact
                        path="/reset/finish"
                        publicRoute="true"
                        loggedIn={logged}
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage {...props} authState={STATE_RECOVER_FINISH} />
                        )}
                     />
                     <LayoutRoute
                        exact
                        path="/activation/finish"
                        publicRoute="true"
                        loggedIn={logged}
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage {...props} authState={STATE_ACTIVATE_CREATE_NEW_PASS} />
                        )}
                     />
                     <LayoutRoute
                        exact
                        path="/correo-enviado"
                        publicRoute="true"
                        loggedIn={logged}
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage {...props} authState={STATE_RECOVER_MAIL_SENT} />
                        )}
                     />
                     <LayoutRoute
                        exact
                        path="/clave-restaurada"
                        publicRoute="true"
                        loggedIn={logged}
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage {...props} authState={STATE_RESETED_PASS} />
                        )}
                     />

                     <LayoutRoute
                        exact
                        publicRoute="true"
                        loggedIn={logged}
                        path="/signup-profesional"
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage
                              {...props}
                              authState={STATE_SIGNUP}
                              roleAssigned={Role.PROFESSIONAL}
                           />
                        )}
                     />
                     <LayoutRoute
                        exact
                        publicRoute="true"
                        loggedIn={logged}
                        path="/cuenta-creada"
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage {...props} authState={STATE_CREATED}/>
                        )}
                     />
                     <LayoutRoute
                        exact
                        publicRoute="true"
                        loggedIn={logged}
                        path="/activate"
                        layout={AuthLayout}
                        component={(props) => (
                           <Authpage {...props} authState={STATE_ACTIVATE}/>
                        )}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/actualizar-contrasenia"
                        layout={Layout}
                        component={(props) => (
                           <PasswordUpdate {...props} PasswordUpdate/>
                        )}
                     />
                     <LayoutRoute
                        exact
                        path="/"
                        publicRoute="true"
                        loggedIn={logged}
                        layout={EmptyLayout}
                        component={() => <Home />}
                     />
                     <LayoutRoute
                        exact
                        publicRoute="true"
                        loggedIn={logged}
                        path="/gmail/callback"
                        layout={Layout}
                        component={(props) => <GmailCallback {...props} />}
                     />
                     <LayoutRoute
                        exact
                        path="/logout"
                        publicRoute="true"
                        loggedIn={logged}
                        layout={EmptyLayout}
                        component={() => <Home />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        publicRoute="true"
                        authNotRequired="true"
                        path="/terminos-y-condiciones"
                        layout={Layout}
                        component={(props) => <TermsAndConditions {...props} />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/registro-completo"
                        layout={Layout}
                        component={(props) => <FullRegistration {...props} />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/nuevo-miembro-familiar"
                        layout={Layout}
                        component={(props) => <NeaFamilyMember {...props} newFambMember={true}/>}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/ayuda"
                        layout={Layout}
                        component={(props) => <Help {...props} />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/preguntas-frecuentes"
                        layout={Layout}
                        component={() => <FrequentQuestion />}
                     />
                     <LayoutRoute
                        exact
                        path="/grupo-familiar"
                        loggedIn={logged}
                        layout={Layout}
                        component={() => <FamilyGroup />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/notificaciones"
                        layout={Layout}
                        component={(props) => <Notifications {...props} />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/mi-plan"
                        layout={Layout}
                        component={(props) => <MyPlan {...props} />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/profesional/agenda-de-turnos"
                        layout={Layout}
                        component={(props) => (
                           <AppointmentSchedule {...props} />
                        )}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/profesional/disponibilidad"
                        layout={Layout}
                        component={(props) => (
                           <TimeAvailabilitySchedule {...props} />
                        )}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/profesional/liquidaciones"
                        layout={Layout}
                        component={() => <Liquidation />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/ayuda"
                        layout={Layout}
                        component={() => <Help />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/preguntas-frecuentes"
                        layout={Layout}
                        component={() => <FrequentQuestion />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/historia-clinica"
                        layout={Layout}
                        component={() => <HistoryClinic />}
                     />
                     <LayoutRoute
                        exact
                        loggedIn={logged}
                        path="/historia-clinica/:id"
                        layout={Layout}
                        component={(props) => <HistoryClinicDetail {...props}/>}
                     />
                     <VirtualGuardProvider>
                        <LayoutRoute
                           exact
                           loggedIn={logged}
                           path="/profesional"
                           layout={Layout}
                           component={(props) => <Professional {...props} />}
                        />
                        <LayoutRoute
                           exact
                           loggedIn={logged}
                           path="/profesional/videollamada"
                           layout={Layout}
                           component={() => <ProfessionalVideoCall />}
                        />
                        <LayoutRoute
                           exact
                           path="/paciente"
                           layout={Layout}
                           component={(props) => <Patient {...props} />}
                        />
                        <LayoutRoute
                           exact
                           loggedIn={logged}
                           path="/paciente/mis-recetas"
                           layout={Layout}
                           component={() => <Prescription />}
                        />
                        <LayoutRoute
                           exact
                           loggedIn={logged}
                           path="/paciente/mis-pedidos"
                           layout={Layout}
                           component={() => <MyOrders />}
                        />
                        <LayoutRoute
                           exact
                           loggedIn={logged}
                           path="/paciente/mis-turnos"
                           layout={Layout}
                           component={() => <MyAppointments />}
                        />
                        <LayoutRoute
                           exact
                           loggedIn={logged}
                           path="/paciente/videollamada"
                           layout={Layout}
                           component={(props) => (
                              <PatientVideoCall {...props} />
                           )}
                        />
                     </VirtualGuardProvider>
                  </Switch>
               </BrowserRouter>
               <ProfessionalLogHook  />
            </ToastProvider>
                        
            
         </ThemeProvider>
         </ProfessionalLogState>
      </AuthState>
   );
};

export default App;
